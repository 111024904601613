$primary-blue: rgb(0, 58, 136);
$menu-hover: rgb(35, 121, 192);
$primary-white: #fefefe;
$transparent-white: #fdfdfdd1;
$primary-light-grey: rgb(244, 244, 244);
$primary-grey: #b5b5b5;
$primary-grey-hover: rgb(235, 235, 235);
$circle-green: #77974c;
$circle-purple: #895375;
$circle-blue: #4490cd;
$accessible-circle-blue: #005daa;
$circle-orange: #de6839;
$circle-yellow: #e8a317;
$circle-gray: #6d6e71;
$link-blue: #005daa;
$link-dark-grey: #434343;
$nav-menu-dropdown: #e0e0e0;
$primary-light-blue: #4baeff;
$primary-black: #222222;
$black-hover: rgb(8, 8, 8);
$black-active: rgb(0, 0, 0);
$view-action-blue: #0071d1;
$table-light-grey: #f4f4f4;
$table-white: #fafafa;
$activity-date-grey: #aaaaaa;
$bill-details-grey: #707070;
$green-hover: #8eb55a;
$green-active: #526933;
$mobile-nav-other-links: #b1b1b1;
$danger-red: #bf0000;
$danger-red-hover: #cb3232;
$danger-red-active: #980000;
$light-blue: #4285f4;
$light-blue-hover: rgb(90, 148, 241);
$light-blue-active: rgb(51, 126, 247);
$dark-blue: #3b5998;
$dark-blue-hover: rgb(72, 97, 151);
$dark-blue-active: rgb(43, 76, 148);
$orange: #de6839;
$orange-hover: rgb(219, 113, 72); //TODO verify colors with mockup
$orange-active: rgb(204, 95, 53); //TODO verify colors with mockup
$breakpoint-mobile-max: 1417px;
$breakpoint-desktop-min: 1418px;
$header-height: 97px;
$sidebar-width: 270px;

// Media Query Replacements
$desktop-min: '(min-width: 1236px)';

@mixin desktop {
    @media screen and #{$desktop-min} {
        @content;
    }
}

/* Usage Example
    @include './??/styles/variables.scss'
    #id {
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }
    }
*/
