.slightlyLargerMarginBottom {
    margin-bottom: 20px !important;
}

.billingNotificationSettingsNote {
    margin-bottom: 25px;
    color: #de6839;
    font-size: 16px;
    font-family: 'Lato';
}

.saveBtn {
    max-width: 100px;
}

.actionsContainer {
    display: flex;
    justify-content: center;
}
