
@import '../../styles/variables';

.iconContainer {
    position: relative;
}

.icon {
    position: relative;
    min-width: 51px;
    min-height: 51px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;

    &.small {
        width: 51px;
        height: 51px;
    }

    &.medium {
        width: 101px;
        height: 101px;
    }

    &.large {
        width: 201px;
        height: 201px;
    }

    &.clickable {
        &:hover {
            transition: all 0.2s ease-in-out;
            -webkit-filter: blur(3px); /* Chrome, Safari, Opera */
            filter: blur(3px);

            +.editIcon {
                opacity: 100;
            }
        }
    }
}

.badge {
    font-size: 16px;
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: #BF0000;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-white !important;
}

.editIcon {
    pointer-events: none;
    transition: all 0.2s ease-in;
    opacity: 0;
    display: flex;
    position: absolute;
    left: 92px;
    top: 0px;
    justify-content: center;
    align-items: center;
    height: 100%;
}