iframe#citibot {
    box-shadow: 0 0 5px grey;
    border-radius: 10px;
    border: none !important;
    z-index: 2147483000;
    position: fixed;
    bottom: 17vh;
    right: 20px;
    width: 450px;
    height: 60vh;
    opacity: 0;
    display: none;
    transition: opacity 0.3s ease 0s;
}

@keyframes appear {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes appear_out {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(40px);
    }
}

.citibot_in {
    animation: appear_out 0.4s ease-out;
}
.citibot_out {
    animation: appear 0.4s ease-out;
}

@media only screen and (min-height: 850px) {
    #citibot {
        height: 60vh !important;
    }
}

@media only screen and (max-width: 430px) {
    #citibot {
        width: 90vw !important;
        height: 60vh;
    }
}
