@import '../../../../styles/variables.scss';

.header {
    display: inline-flex;
    justify-content: space-between;

    h2 {
        display: inline;
    }

    .headerButtons {
        button {
            margin-left: 32px;
        }
    }

    .statusTag {
        display: inline-block;
        border-radius: 4px;
        color: white;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
        height: 30px;
        margin-top: 10px;
        font-family: 'Lato';
        text: {
            align: center;
            transform: capitalize
        }
        font: {
            size: 20px;
        }
    
        background-color: $accessible-circle-blue;
    }
}

.informationNeededHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h3 {
        margin: 0px;
        padding: 0px;
        margin-top: 8px;
        margin-left: 5px;
    }
}

.informationNeededDiv {
    margin-bottom: 10px;
}

.informationNeededParagraph {
    margin: 0px;
    margin-bottom: 5px;
}

.marginBottom {
    margin-bottom: 20px;
}

.informationNeeded {
    h3 {
        color: black;
    }

    p {
        color: black;
        font-size: 18px;
        font-weight: bold;
    }

}

.detailContainer {
    display: flex;
    flex-wrap: wrap;

    .detailItemContainer {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 20px;
        font-family: Avenir;

        .detailItemLabel {
            color: $primary-blue;
            font-size: 18px;
            font-weight: bold;
        }

        .detailItemInfo {
            color: $primary-black;
            font-size: 16px;
            font-weight: 500;
        }
    }
}