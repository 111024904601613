@import '../../styles/variables';

.graphNoData {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.graphHeader {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.graphHeaderV2 {
    color: #302E2E;
    font-family: 'Lato' !important;
    font-size: 24px;
    font-weight: 800;
    position: absolute;
    margin-left: 10px;
}

.unitsBilledNote {
    margin-bottom: 0;
    color: #707070;
    font-size: 14px;
    font-style: italic;
    margin-left: 10px;
}
