.page_container {
    padding: 20px;
    flex-grow: 1;
}

.listing_table {
    margin-top: 30px;
}

.list_item {
    height: 50px;
    font-size: 16px;
    cursor: pointer;
}

.item_text {
    display: inline-block;
    font-size: 16px;
    margin: 14px 24px;
}
