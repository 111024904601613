@import "../../styles/variables";

.alertInnerPanelContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.alertLeftInnerPanelContainer {
  max-width: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: red;
}

.alertHeaderIcon {
  font-size: 35px;
  color: $circle-yellow;
  display: inline;
  margin-right: 10px;
}

.alertHeader {
  color: $circle-yellow;
  font-weight: bold;
  font-family: "Lato" !important;
  display: flex;
  align-items: center;
}

.alertHeaderText {
  display: inline;
  font-size: 30px;
  font-family: "Lato" !important;
  margin-bottom: 20px;
  color: $circle-yellow;
}

#spacer {
  visibility: hidden;
  flex-grow: 1;
}

.dismiss {
    font-size: 16px;
    color: $circle-yellow;
    transition: color 0.2s;
}

.alertInnerButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-left: 50%;
  padding-right: 50%;
}

.dismissAlert {
  font-family: 'Lato';
  font-weight: bold;
}
