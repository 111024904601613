.modalContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  // align-items: ;
  justify-content: space-evenly;

  & > div {
    width: 100%;
  }
}

.linkText {
  cursor: pointer;
  color: #4183c4;
  &:hover {
    color: #1e70bf;
  }
}

.submitContainer {
  display: flex;
  justify-content: flex-end;
}

.accountNumberTitleContainer {
  margin-bottom: 15px;
}

.accountNumberInputContainer {
  margin-bottom: 15px;
}

.slightMarginRight {
  margin-right: 5px;
}
