@import '../../styles/variables';

.dropdown {
    font-family: 'Avenir';
    font-size: 16px;
    min-height: auto !important;
    padding: .65em 2.1em .65em 1em !important;
    flex: 1;
    max-height: 39px;
    div {
        color: $primary-black !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    i {
        line-height: normal !important;
        top: auto !important;
        color: $link-dark-grey;
    }

    &.dark {
        background-color: $nav-menu-dropdown !important;
        color: $link-dark-grey;
        border-color: transparent !important;
    }

    &:focus-within {
        border-color: #96c8da !important;
        border-width: 1px !important;
        border-style: solid !important;
    }
}