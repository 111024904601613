@import '../../../styles/variables';

.progressBarContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
}

.barContainer {
    margin: 0 65px;
}

.progressBarRow {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.progressBarItem {
    width: 160px;
    text-align: center;
    color: $primary-grey;
    
    &.active {
        color: $circle-green;
    }
}

ul.progressBar {
    height: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        background: #B5B5B5;
        height: 5px;
        width: 100vw;
    }
    li {
        background: $circle-green;
        border-radius: 100px;
        width: 15px;
        height: 15px;
        z-index: 1;
        position: relative;
        &.stop {
            background: #B5B5B5;
            &::after {
                // height: 0;
                background: #B5B5B5;
                z-index: 2;
            }
        }
        &.stop ~ li {
            background: #B5B5B5;
            &::after {
                height: 0;
            }
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 5px;
            background: $circle-green;
            height: 5px;
            width: 100vw;
        }
    }
}

@media only screen and (max-width: $breakpoint-mobile-max) {
    .progressBarContainer {
        margin-bottom: 25px;
    }

    .barContainer {
        margin: 0 45px;
    }
}