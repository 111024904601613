@import '../../styles/variables';

.form {
    display: flex;
    flex-direction: column;
}

.hozForm {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.center {
    align-self: center;
}

.hrule {
    width: 200px;
    color: #EAEAEA;
}

.page {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.fullWidthPage {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    width: 100%;
    max-width: 950px;
}

.signup {
    background-color: $nav-menu-dropdown !important;
    color: $link-dark-grey !important;
    padding: 7px;
    margin: 0px;
    border-radius: .28571429rem;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    font-family: 'Avenir';
    line-height: initial;

    &:hover {
        background-color: $primary-grey-hover !important;

    }

    &:active {
        background-color: $primary-grey !important;
    }
}

hr {
    margin-left: 30px;
    margin-right: 30px;
    border-top-width: 2px;
    height: 2px;
    background-color: #EAEAEA;
    border-style: none;
}

.buttonWithIcon {
    justify-content: center;
    display: flex !important;
    align-items: center;

    > i {
        margin-right: 10px;
    }
}

.orWrapper {
    position: relative;
    width: 50px;
    height: 300px;
    margin: 10px;

    .orLine {
        position: absolute;
        left: 49%;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #ccc;
        z-index: 1;
    }

    .orWordWrapper {
        text-align: center;
        height: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -12px;
        z-index: 2;

        .orWord {
            color: #ccc;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 3px;
            font: bold 12px arial,sans-serif;
            background: #fff;
        }
    }
}

.delayedAccountText {
    color: #707070;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
}

.makePaymentAsGuestButtonContainer {
    text-align: center;
    margin-top: 15px;
}
