@import '../../../styles/variables.scss';

.tableContainer {
    margin-bottom: 20px;
}

.tableHeader {
    height: 50px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px;
    font-weight: bold;
}

.name, .status, .timestamp {
    min-width: 33%;
    display: flex;
}

@include desktop {
    .name {
        min-width: 60%;
    }
    .status, .timestamp {
        min-width: 20%;
    }
}

.item {
    min-height: 50px;
    height: fit-content;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px;
    color: inherit;

    .statusTag {
        display: inline-block;
        border-radius: 4px;
        color: white;
        width: 150px;
        height: fit-content;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 4px 0px;
        text: {
            align: center;
            transform: capitalize
        }
        font: {
            size: 14px;
            weight: bold;
        }

        background-color: $circle-blue;

        &.orange { background-color: $circle-orange;}
        &.blue { background-color: $circle-blue;}
        &.red { background-color: $danger-red;}
        &.green { background-color: $circle-green;}
        &.yellow { background-color: $circle-yellow;}
    }
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}
