@import '../../../styles/variables';

.innerPanelContainer {
    padding: 30px 15px;
}

.backToHomepageArrow {
    margin-bottom: 20px;
}

.userInfoColumn {
    padding-top: 70px;
    padding-bottom: 70px;
    width: 300px;
    border-right: 1px solid #E0E0E0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Lato' !important;
    font-weight: bold !important;
    word-wrap: break-word;
    text-align: center;

    .profilePicture {
        width: 146px;
        height: 146px;
        margin-bottom: 35px;
        clip-path: circle();
    }

    .fullName {
        width: 250px;
        color: #00386A;
        font-size: 24px;
        margin-bottom: 15px;
        margin-top: 0;
        font-family: 'Lato' !important;
        font-weight: bold !important;
    }

    .smallerUserDetails {
        width: 250px;
        font-size: 15px;
        margin-bottom: 8px;
    }
}

.userViewColumn {
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 30px;
    width: 70%;
    font-family: 'Lato' !important;

    .outerViewUserPanelHeader {
        margin-bottom: 35px;
    }

    .innerViewUserPanelHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .activityHistoryHeader {
        margin-top: 70px;
        margin-bottom: 35px;
    }

    .editUserHeader {
        margin: 0;
    }

    .inputLabel {
        font-weight: 700;
        font-family: 'Lato';
        font-size: 16px;
        margin-bottom: 5px;
    }
}

.backToPreviousPage {
    margin-bottom: 20px;
}

.spacer {
    display: inline-block;
    width: 20px;
}



/* for the payment history table */

.topPanelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.blueHeading {
    color: #00386A;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
}

.paymentAccountText {
    margin-bottom: 5px;
}

.totalDueText {
    margin-bottom: 5px;
}

.delinquentAmountText {
    margin-bottom: 5px;
    color: #bf0000 !important;
    font-weight: bold !important;
    font-family: 'Lato' !important;
}

.todaysDateContainer {
    background-color: #F8F8F8;
    height: 40px;
    display: flex;
    align-items: center;
    padding-top: 3px;
    border-radius: 5px;

    .blueHeading {
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 16px;
    }
}

.containerWeb {
    margin-left: 5px;
    width: 99.5%;
}

.containerMobile {
    display: none;
}

.paymentHistoryTableHeader {
    text-align: center;
    background: #F8F8F8;
    color: #131313;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.paymentHistoryDateHeader {
    text-align: left !important;
}

.activityHistoryTextAlign {
    text-align: left !important;
}

.colHeaderText {
    font-size: 14px !important;
}

.colGroupPaymentDate {
    width: 20%;
}

.colGroupPaymentType {
    width: 50%;
}

.colGroupPaymentAmount {
    width: 20%;
}

.colGroupActivityDate {
    width: 20%;
}

.colGroupActivityType {
    width: 20%;
}

.colGroupActivityDescription {
    width: 50%;
}

.priceHistoryTableDate {
    padding-left: 10px;
    color: #00386A;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Lato';

    padding-top: 15px;
    padding-bottom: 15px;
}

.priceHistoryTableType {
    color: #00386A;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Lato';
    text-align: center;
}

.priceHistoryTableAmount {
    color: #00386A;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Lato';
    text-align: center;
}

.activityHistoryTableText {
    color: #00386A;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Lato';
    padding-left: 10px;
}

.containerMobile {
    margin-left: 5px;
    width: 99.5%;
}

.tableRow {
    border-bottom: 1px solid #AAAAAA;
}

.colGroupLink {
    width: 20%;
}

.colGroupPrice {
    width: 5%;
}

.colGroupButtons {
    width: 10%;
}

.billLinkContainer {
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.billLinkText {
    color: #00386A;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
    text-decoration: underline;
    margin-bottom: 0;
    width: 200px;
    cursor: pointer;
}

.billNoLinkText {
    color: #00386A;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
    margin-bottom: 0;
    width: 200px;
}

.billLinkText:hover {
    color: #0071d4;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
    text-decoration: underline;
}

.accountInformationText {
    color: #707070;
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 0;
}

.dueDate {
    margin-top: 5px;
    color: #707070;
    font-size: 14px;
}

.priceText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 800;
}

.buttonSpacer {
    margin-left: 10px;
}

.paymentInfoContainer {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: 15px;
    border-bottom: 1px solid #AAAAAA;
    padding-top: 20px;
    padding-bottom: 20px;
}

.mobileBillLinkText {
    margin-top: 5px;
}

.mobileTypeText {
    margin-top: 5px;
    color: black;
    font-family: 'Lato';
    font-size: 16px;
}

.mobileDateText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 800;
}

.mobilePaymentPriceText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 800;
    margin-top: 10px;
}

.mobilePriceText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 800;
}


.singleButtonContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.paymentIncomplete {
    font-family: 'Lato';
    color: #BF0000;
    font-weight: 800;
}

.paymentComplete {
    font-family: 'Lato';
    color: #77974C;
    font-weight: 800;
}

.buttonContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}


.billPayAddPaymentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;

    .billPayAddPaymentActionV2 {
        font-size: 18px;
    }
}

.noRecordsAvailableText {
    text-align: center;
}

.noRecordsAvailableMargin {
    margin-top: 8px;
}

.activityUserSessionDescriptionParagraphs {
    margin-top: 0;
    margin-bottom: 5px;
}
