@import '../../styles/variables';

.webMessage {
    width: 100%;
    display: flex;
    padding: 5px 16px;
    &:not(.webMessageHeader) {
        cursor: pointer;
    }
}

.webMessageHeader {
    font-weight: bold;
}

.webMessageLeftColumn {
    width: 70%;
    margin-right: 15px;
    display: flex;
}

.webMessageReadIndicator {
    width: 12px;
    margin-right: 16px;
    display: flex;
    align-items: center;
}

.webMessageUnread {
    width: 12px;
    height: 12px;
    background-color: $circle-blue;
    border-radius: 50%;
}

.webMessageRightColumn {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.webMessageDate {
    margin: 0;
}

.mobileMessage {
    display: flex;
}

.mobileMessageReadIndicator {
    width: 9px;
    margin: 0px 3px;
    align-self: center;
    justify-self: center;
}

.mobileMessageUnread {
    width: 9px;
    height: 9px;
    background-color: $circle-blue;
    border-radius: 50%;
}

.mobileMessageContent {
    width: calc(100% - 9px - 6px);
    display: flex;
    flex-direction: column;
}

.mobileMessageHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mobileMessageDate {
    margin: 0px;
    font-size: 12px;
}

.messageTitle {
    font-weight: bold;
    margin: 0;
}

.messageBody {
    font-size: 14px;
    margin: 0;
}

.trash {
    color: $danger-red;
    &:hover {
        color: scale-color($danger-red, $lightness: -35%);
    }
}

.errorText {
    color: $danger-red;
    width: 100%;
    text-align: center;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .mobileMessage { display: flex; }
    .webMessage { display: none; }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .mobileMessage { display: none; }
    .webMessage { display: flex; }
}