@import '../../../../styles/variables.scss';

.statusContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    // padding on arrow
    div:nth-child(2) {
        margin-right: 5px;
    }

    .statusBadge {
        background-color: $primary-grey-hover;
        box-shadow: 0px 3px 6px #00000029;
        color: $primary-black;
        font-size: 18px;
        font-family: Avenir;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 71px;
        min-width: 183px;
        border-radius: 10px;
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 10px;

        &.active {
            background-color: $primary-blue;
            color: $primary-white;
        }

        &.terminated {
            background-color: $danger-red;
            color: $primary-white;
        }

        &.past {
            background-color: $circle-green;
            color: $primary-white;
        }
    }
}