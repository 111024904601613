@import '../../styles/variables';

.button {
    font-family: 'Lato' !important;
    font-weight: 800 !important;
    font-size: 16px !important;
    margin-bottom: 15px;
    line-height: initial !important;
    flex: 1;
    padding: 7px 1.5em !important;
    margin-right: 0px !important;
    border-radius: 10px !important;
    width: 183px;
    height: 40px;
}

.blue {
    background-color: $link-blue !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: $view-action-blue !important;
    }

    &:active {
        background-color: $primary-blue!important;
    }
}

.green {
    background-color: #129a39 !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: #117b2f !important;
    }

    &:active {
        background-color: #117b2f !important;
    }
}

.limegreen {
    background-color: #77974C !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: #6a8644 !important;
    }

    &:active {
        background-color: #6a8644 !important;
    }
}

.red {
    background-color: $danger-red !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: $danger-red-hover !important;
    }

    &:active {
        background-color: $danger-red-active!important;
    }
}

.grey {
    background-color: $nav-menu-dropdown !important;
    color: $link-dark-grey !important;

    &:hover {
        background-color: $primary-grey-hover !important;
    }

    &:active {
        background-color: $primary-grey !important;
    }
}

.gray {
    background-color: white !important;
    color: #ACACAC !important;
    border: 2px solid #ACACAC !important;

    &:hover {
        background-color: $primary-grey-hover !important;
    }

    &:active {
        background-color: $primary-grey !important;
    }
}

.lightBlue {
    background-color: $light-blue !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: $light-blue-hover !important;
    }

    &:active {
        background-color: $light-blue-active !important;
    }
}

.darkBlue {
    background-color: $dark-blue !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: $dark-blue-hover !important;
    }

    &:active {
        background-color: $dark-blue-active !important;
    }
}

.orange {
    background-color: $orange !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: $orange-hover !important;
    }

    &:active {
        background-color: $orange-active !important;
    }
}

.black {
    background-color: $primary-black !important;
    color: #F4F4F4 !important;

    &:hover {
        background-color: $black-hover !important;
    }

    &:active {
        background-color: $black-active !important;
    }
}
