@import '../../styles/variables';

.form {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: column;
    width: 260px;
    padding-bottom: 15px;
}

.center {
    align-self: center;
}

.hrule {
    width: 200px;
    color: #EAEAEA;
}

.page {
    display: flex;
    justify-content: center;
    height: 100%;
}