.page_container {
    display: flex;
    flex: {
        direction: column;
        grow: 1;
    }
    height: 100%;
}

.iframe_container {
    height: 100%;
}

.iframe_container iframe {
    height: 100% !important;
}