@import '../../../styles/variables';

.pageContainer {
    width: 100%;
    max-width: 920px;
    align-items: center;
    margin: 0 auto;
}

.titleContainer {
    width: 100%;
    padding: {
        left: 60px;
        top: 70px;
        bottom: 20px;
    }
}

.formContainer {
    width: 720px;
    max-width: 100%;
    justify-self: center;
    
    > div {
        margin-bottom: 10px;
    }
}

.addOrganizationInformation {
    margin-bottom: 20px;
    h3 {
        margin-bottom: 0px;
    }
}

.cityStateZip {
    display: flex;
    flex: {
        direction: row;
        wrap: wrap;
    }
}

.city {
    flex-grow: 6;
}

.state {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.zip {
    flex-grow: 2;
}

.choiceContainer {
    display: flex;
    flex: {
        direction: column;
    };
}

.utilityHelpContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.utilityHelpText {
    flex-grow: 1;
    margin-bottom: 10px;
}

.utilityHelpImageContainer {
    flex-grow: 2;
}


.utilityHelpImage {
    background: url('../../../static/img/elgin-utility-account-help-marked.png') no-repeat center;
    width: 100%;
    max-width: 550px;
    height: 272px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 20px;
}

.propertiesContainer {
    display: flex;
}

.propertyListingContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.propertyListing {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $primary-grey;
    div {
        margin-bottom: 0px;
    }
}

.propertySpace {
    flex-grow: 2;
}

.address {
    display: flex;
    flex: {
        direction: column;
    }
    margin: {
        top: 5px;
        left: 20px;
        right: 30px;
    } 
}

.myOrganizationsContainer {
    display: flex;
    border-top: 1px solid $primary-grey;
    flex-direction: column;
}

.myOrganizationsItem {
    display: flex;
    justify-content: space-between;
    margin: {
        top: 10px;
        left: 20px;
        right: 20px;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.submitBusinessButton {
    margin-top: 10px;
}

.linkText {
    cursor: pointer;
    color: #4183c4;
    &:hover {
        color: #1e70bf;
    }
}
.inputContainer {
    display: flex;
    margin-bottom: 0px !important;
}

.firstName {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 10px !important;
}

.lastName {
    flex: 1;
    margin-bottom: 10px !important;
}

.slightMarginBottom {
    margin-bottom: 10px !important;
}

.slightMarginTop {
    margin-top: 10px !important;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: $breakpoint-mobile-max) {
    .titleContainer {
        padding: 0;
        display: block;
        max-width: 100%;
        margin: 20px 0;
        text-align: center;
        h2 {
            margin: 0 auto;
        }
    }

    .buttons {
        flex-wrap: wrap;
        .buttonContainer {
            margin: 10px 0;
        }
    }

    .buttonContainer {
        display: flex;
        flex: 0 0 100%;
        justify-content: center;
    }

    .label {
        width: 100%;
        margin-left: 10px;
    }

    .myOrganizationsContainer {
        margin-bottom: 25px !important;
    }

    .state {
        padding: 0;
        margin-bottom: 10px;
    }

    .cityStateZip {
        margin-bottom: 0 !important;
    }

    .city, .state, .zip {
        flex: 0 0 100%;
    }

    .choiceContainer {
        margin-left: 10px;
    }

    .utilityHelpImageContainer {
        flex: 0 0 100%;
    }
}