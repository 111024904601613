@import '../../styles/variables.scss';

.termWrapper {
    display: flex;
    flex-direction: column;

    @include desktop {
        flex-direction: row;
    }

    div {
        flex: 1;
    }

    button {
        width: 100%;
        margin-top: 10px;

        @include desktop {
            margin-top: 0;
            width: auto;
            margin-left: 20px;
            max-width: 120px;
        }
    }
}

h3 {
    margin-top: 20px;
}

.searchResult {
    margin-bottom: 30px;

    .titleLink:hover {
        text-decoration: underline;
    }

    .urlDisplay {
        font-size: 12px;
        color: $bill-details-grey;
    }

    .titleDisplay {
        font-size: 18px;
    }

    .highlightDisplay {
        font-size: 14px;
    }
}

.pageButton {
    margin-left: 20px;
    margin-right: 20px;

    &:hover {
        cursor: pointer;
        color: $menu-hover;
    }
}

.loading {
    width: 100%;
    margin: 24px;
    flex: 1;
    display: flex;
    justify-content: center;
}
