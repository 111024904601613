@import '../../styles/variables';

.text {
    font-size: 16px;
    max-height: 39px;
    display: flex;
    padding: 0px !important;
    color: $primary-black !important;
    outline: none;
    margin-bottom: 10px;

    input {
        color: $primary-black !important;
    }

    &:focus {
        outline: none;
    }
}

.fontFamilyAvenir {
    font-family: 'Avenir' !important;
}

.fontFamilyLato {
    font-family: 'Lato' !important;
}
