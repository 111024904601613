.heading {
  margin-bottom: 15px;
}

.sortIcon {
  font-size: 14px;
  margin-left: 5px;
}

/* styles for react-table */
:global(.-btn) {
  background-color: #003A88 !important;
  color: #FDFDFD !important;
  font-weight: bold;
  font-size: 14px !important;
  border-radius: 5px !important;
  padding-top: 7px !important;
  border-radius: 10px !important;
}

:global(.ReactTable .rt-thead.-header) {
  box-shadow: none !important;
}

:global(.ReactTable .rt-thead .rt-th.-sort-asc) {
  box-shadow: none !important;
}

:global(.ReactTable .rt-thead .rt-th.-sort-desc) {
  box-shadow: none !important;
}

:global(.-pageJump) {
  input {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
}

:global(.ReactTable) {
  border: none;
  margin-bottom: 10px;
}

:global(.user-table-cell-actions) {
  display: flex;
  width: 100%;
  justify-content: center;
}
