
.mobileStyle {
    border: none;
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
}

.webStyle {
    border: none;
    width: 100%;
    margin: auto;
    min-height: 500px;
}
