@import '../../../styles/variables.scss';

.page {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    background-image: linear-gradient(
      to bottom, transparent, transparent 70%, $primary-white
    ), url("../../../static/img/Elgin-City-View.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

html.webp .page {
    background-image: linear-gradient(
      to bottom, transparent, transparent 70%, $primary-white
    ), url("../../../static/img/Elgin-City-View.webp");
}

.panelContainer {
  width: 100%;
}

.container {
    min-width: 300px;
}

.panelStyle {
  align-self: center;
  max-width: 650px;
  @media only screen and (max-width: 650px) {
    max-width: 100%;
  }
}