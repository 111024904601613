@import '../../styles/variables.scss';

.innerPanelContainer {
    text-decoration: none;
    color: $primary-black;
}

a .content {
    color: $link-blue;
}

.buttonContainer {
    width: 200px;
    margin: 0 auto;
}

.panelHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}