@import '../../../styles/variables';

.innerPanelContainer {
    max-width: 745px;
}

.inputLabel {
    font-weight: 700;
    font-family: 'Lato';
    font-size: 13px;
    margin-bottom: 5px;
}

.inputLabelNote {
    display: block;
    font-family: 'Lato';
    font-size: 13px;
    margin-bottom: 5px;
}

.row {
    margin-bottom: 20px;
}

.orderContainer {
    max-width: 106px;
}

.pageHeader {
    display: flex;
}

.submitContainer {
    max-width: 91px;
    margin-left: auto;
}

.activitiesLink {
    max-width: 175px;
}

.topError {
    color: $danger-red;
    width: 100%;
    text-align: center;
}


input[type="file"] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 8px;
    font-size: 17px;
    color: #b8b8b8;
}

.buttonWrap {
    position: relative;
    display: flex;
    align-items: center;
}

.fileUploadButton {
    display: inline-block;
    padding: 6px 1.5em;
    cursor: pointer;
    border-radius: 5px;
    background-color: #005daa;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    font-family: 'Avenir';
    font-size: 15px;
    height: 32px;
}

.activityIcon {
    display: inline-block;
    margin-right: 20px;
    max-width: 32px;
    max-height: 32px;
}
