@import '../../styles/variables';

.checkbox {
    margin-right: 10px;

}

.checkboxContainer {
    display: flex;
    label {
        display: flex;
        flex-direction: row;
        align-items: center;
        font: {
            weight: 500;
            size: 16px;
            family: 'Avenir';
        }
        color: $primary-black !important;
    }
}