@import '../../../styles/variables';

.page {
    max-width: 920px;
}

.columnContainer {
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.column {
    width: 49%;
    > div {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: $breakpoint-mobile-max) {
    .column {
        width: 100%;
    }
    .page {
        max-width: 100%;
    }
}

.reportHeader {
    margin: 15px;

    .type {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .instructions {
        font-size: 16px;
    }
}

.reportFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 15px;
}

.section {
    width: 100%;
    border: 1px solid $primary-black;
    border-radius: 10px;
}

.sectionHeader {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.fileUpload {
    border: 3px dashed $primary-blue;
    border-radius: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.fileSizeLimit {
    text-align: right;
    font-style: italic;
}

.fileSizeError {
    text-align: center;
    color: $danger-red;
    margin-top: 5px;
}

.fileList {
    display: flex;
    flex-direction: column;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    margin: 5px;

    > div {
        display: flex;
    }
}

.spinnerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorMessage {
    color: $danger-red;
}

.errorMessageHeader {
    text-align: 'right'; 
    margin-top: 10;
}

.errorMessageLi {
    padding-top: 0;
    margin-top: 0;
}

/* stars */
.starRating {
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    height: 50px;
    overflow: hidden;
    position: relative;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
    margin: auto;
}
.starRating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=');
    background-size: contain;
}
.starRating input {
    -moz-appearance: none;
    -webkit-appearance: none;
    opacity: 0;
    display: inline-block;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
}
.starRating input:hover + i,
.starRating input:checked + i {
    opacity: 1;
}
.starRating i ~ i {
    width: 40%;
}
.starRating i ~ i ~ i {
    width: 60%;
}
.starRating i ~ i ~ i ~ i {
    width: 80%;
}
.starRating i ~ i ~ i ~ i ~ i {
    width: 100%;
}
::after,
::before {
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
}
  
.starRating {width: 250px;}
.starRating input,
.starRating i {width: 20%;}
.starRating i ~ i {width: 40%;}
.starRating i ~ i ~ i {width: 60%;}
.starRating i ~ i ~ i ~ i {width: 80%;}
.starRating i ~ i ~ i ~ i ~i {width: 100%;}
  
  