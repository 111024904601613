@import '../../styles/variables';

.panel {
    display: flex;
    background-color: $primary-white;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 30px 15px;
    @include desktop {
        padding-left: 30px;
        padding-right: 30px;
    }
    flex: 1;
    flex-direction: column;
    font-size: 16px;
    font-family: 'Avenir';
    max-width: 100%;
}

.panelRow {
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: row;
    display: flex;

    &.fluid {
        width: 100%;
    }

    & > .panel:not(:first-child):not(:last-child) {
        margin-left: 15px;
        margin-right: 15px;
    }

    & > .panel:first-child {
        margin-right: 15px;
    }


    & > .panel:last-child {
        margin-left: 15px;
    }

    & > .panel:first-child:nth-last-child(1) {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@keyframes blink {
    50% {
        border-color: $circle-orange;
    }
}

.panelHighlighted{ /*or other element you want*/
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    animation: blink .6s step-end 6 alternate;
    scroll-margin-top: 120px;
}
