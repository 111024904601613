@import '../../../styles/variables';

.page {
    display: flex;
    flex-direction: column;
}

.nameContainer {
    min-width: 300px;
}

.iconContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.centerContainer {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.addressContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.addressForm {
    width: 100%;
}

.cityStateZip {
    display: flex;
    flex: {
        direction: row;
    }
}

.city {
    flex-grow: 6;
}

.state {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.zip {
    flex-grow: 2;
}

.landParcelContainer {
    margin-top: 10px;
    p {
        margin-bottom: 5px;
    }
}

.submitContainer {
    display: flex;
    justify-content: flex-end;
}

.buttonSpacer {
    display: inline;
    margin-left: 20px;
}

.modalContent {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-evenly;
}

.modalFileUploadContainer {
    display: flex;
    flex-direction: column;
}

.modalFileSizeLimit {
    text-align: right;
    font-style: italic;
}

.modalFileSizeLimitError {
    text-align: right;
    font-style: italic;
    color: $danger-red;
    font-weight: bold;
}

.fileUpload {
    border: 3px dashed $primary-blue;
    border-radius: 10px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.filePreviewContainer {
    display: inline-flex;
    width: 201px;
    height: 201px;
    border-radius: 50%;
    padding: 4px;
    box-sizing: border-box
}

.filePreview {
    display: block;
    border-radius: 50%;
    height: 201px;
    width: 201px;
    object-fit: cover;
}

.slightMarginBottom {
    margin-bottom: 10px !important;
}

.slightlyLargerMarginBottom {
    margin-bottom: 20px !important;
}

.slightMarginTop {
    margin-top: 10px !important;
}

.billingNotificationSettingsHeader {
    margin-bottom: 16px;
}

.billingNotificationSettingsNote {
    margin-bottom: 25px;
    color: #DE6839;
    font-size: 16px;
    font-family: 'Lato';
}
