.buttonWrapper {
    margin-top: 20px;
}

.errorMessage {
    color: red;
    font-weight: bold;
}

.modalContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    // align-itpxs: ;
    justify-content: space-evenly;

    & > div {
        width: 100%;
    }
}

.linkText {
    cursor: pointer;
    color: #4183c4;
    &:hover {
        color: #1e70bf;
    }
}

.submitContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px !important;
    gap: 20px !important;
}

.formContainer {
    margin-top: 15px;
    margin-bottom: 15px;
}

.radioButtonContainer {
    margin-top: 5px;
}

input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Rpxove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: rgba(25, 25, 25, 0.1);
    /* Not rpxoved via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 15px;
    height: 15px;
    border: 1px solid currentColor;
    border-radius: 50%;
    margin: 5px;

    display: inline-flex;
    flex-direction: column;
    place-content: center;
    transform: translateY(-5px);
}

// when input is checked, set background-color to #1e70bf
input[type='radio']:checked {
    background-color: #1e70bf;
}

label {
    vertical-align: text-top;

    align-self: center;
    display: inline;
    padding: 0px 1em 0px 8px;
    justify-content: center;
}

input[type='radio']::before {
    content: '';
    width: 0.65px;
    height: 0.65px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type='radio']:checked::before {
    transform: scale(1);
}

input[type='radio']:focus {
    outline: max(2px, 0.15px) solid #1e70bf;
    outline-offset: max(2px, 0.15px);
}

.enableAutoPayButton {
    padding-bottom: 20px !important;
}

.row {
    display: flex;
    flex-direction: row;
}
