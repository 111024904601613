@import '../../styles/variables';

.searchWrapper {
    display: flex;
    flex-direction: row;
    justify-content: right;
    border-radius: 100px !important;
    width: 100%;

    i {
        align-self: center;
        color: #003a88;
    }
}

.searchInputWrapper {
    display: flex;

    // change width with screen size
    width: 100%;
    max-width: 315px;
    height: 52px;
    background: #e6e6e6;
    color: #222222;
    border-radius: 100px;
    align-items: center;
    align-self: center;
}

.searchIconWrapper:hover {
    cursor: pointer;
    background-color: $primary-blue;

    .searchIcon {
        border: 2px solid #f4f4f4;
        background-color: $primary-blue;
        color: #f4f4f4 !important;

        i {
            color: #f4f4f4 !important;
        }
    }
}

.searchIcon {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: #f4f4f4;

    // put the icon in the center of the div
    display: flex;
    justify-content: center;
    align-items: center;

    // move icon to the left to overlap
    z-index: 100;
    margin-left: -60px;

    i {
        color: $primary-blue;
    }
}

.searchBar {
    // make width change with screen size
    width: 100%;
    max-width: calc(100% - 50px);
    font-size: 16px;
    display: flex;
    padding: 0px !important;
    outline: none;
    height: 52px;

    & > input {
        font-family: 'Avenir' !important;
        color: #222222 !important;
        font-size: 16px;
        outline: none !important;
        background: none !important;
        border: none !important;
        opacity: 1;
        padding-right: 40px;
        // placeholder text color
        &::placeholder {
            color: #222222;
            opacity: 1;
        }
    }
}
