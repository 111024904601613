@import '../../styles/variables';

.panelContainer,
.headerContainer {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.headerTextContainer {
    text-align: center;
}

.detailContainer {
    display: flex;
    flex-direction: column;
    font-family: "Lato";
}

.detailSubContainer {
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailRow {
    font-size: 16px;
    color: $circle-gray;
}

.detailTitle {
    font-size: 20px;
    font-weight: 800;
    color: $primary-black;
}

.detailDescription {
    font-size: 16px;
    color: $circle-gray;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
}

.gridContainerVacancies {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 100%;
    column-gap: 80px;
    row-gap: 20px;
}

.gridSubContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gridItemContainer {
    height: 115px;
    width: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029; // Same as panel component
    border-radius: 10px;
}

.gridItemContainer:hover {
    cursor: pointer;
}

.gridItemSubContainer {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gridItemIconContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 50%;
}

.gridItemTextContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    height: 50%;
}

.gridItemText {
    font-size: 14px;
    font-weight: 800;
}

.gridItemContainerVacancies {
    height: 175px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid $circle-gray;
}

.gridItemSubContainerVacancies {
    height: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gridItemTitleVacancies {
    font-size: 16px;
    font-weight: 800;
    padding-left: 5px;
}

.gridItemTextVacancies {
    font-size: 16px;
}

.gridItemCountVacancies {
    font-size: 48px;
    padding-left: 10px;
}

.gridItemUnavailableVacancies {
    font-size: 16px;
    font-family: "Avenir";
    font-weight: 800;
    background-color: $nav-menu-dropdown;
    color: $link-dark-grey;
    border-radius: 5px;
    padding: 10px;
}

@media only screen and (min-width: 0px) and (max-width: 1400px) {
    .gridContainer {
        grid-template-columns: repeat(4, minmax(0, 1fr)); 
    }
    .gridContainerVacancies {
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
    }
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
    .gridContainer {
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
    }
    .gridContainerVacancies {
        grid-template-columns: repeat(1, minmax(0, 1fr)); 
    }
}