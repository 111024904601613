@import '../../styles/variables';

.topInnerPanelContainer {
    margin-left: 50px;
    margin-right: 50px;
    max-width: 100%;

    .actionOutterTopContainer {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;

        .actionInnerContainer {
            display: flex;
            padding: 10px;
            flex-direction: column;
            max-width: 250px;
        
            &.actionInnerLeftContainer {
                flex: 7;
            }
        
            &.actionInnerMiddleContainer {
                flex: 7;
            }
        
            &.actionInnerRightContainer {
                flex: 5;
                min-width: 198px;
                max-width: 221px;
            }

            .numberHeaderContainer {
                display: flex;
            }
            
            .numberHeader {
                margin-top: auto !important;
                margin-bottom: auto !important;
            }
        }
    }
    
    .actionOutterBottomContainer {
        flex: 2;
        display: flex;
        flex-direction: column;
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 15px;
        text-align: center;

        .errorMessage {
            color: $danger-red;
        }

        .disclaimer {
            margin-top: 15px;
            color: $bill-details-grey;
        }
    }

    .arrow {
        margin-top: 35px;
        max-width: 60px;
    }

    .dropdown {
        max-width: 100%;
    }
}


.topInnerPanelMobileContainer {
    .actionOutterTopContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        margin-right: 15px;

        .actionInnerContainer {
            display: flex;
            padding: 10px;
            flex-direction: column;
        
            .numberHeaderContainer {
                display: flex;
                margin: auto;
            }
            
            .numberHeader {
                margin-top: auto !important;
                margin-bottom: auto !important;
            }
            
            .arrowContainer {
                display: flex;
                margin: auto;
                margin-top: 15px;
            }

            .inputContainer {
                margin: 0 auto;
                width: 209px;
                display: flex;
            }
        }
    }
    
    .actionOutterBottomContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 15px;
        margin-right: 15px;

        .actionInnerContainer {
            display: flex;
            padding: 10px;
            flex-direction: column;
            margin: auto;
        
            .numberHeaderContainer {
                display: flex;
            }
            
            .numberHeader {
                margin-top: auto !important;
                margin-bottom: auto !important;
            }
            
            .arrowContainer {
                display: flex;
                margin: auto;
                margin-top: 15px;
            }

            .inputContainer {
                display: flex;
            }

            .startButtonContainer {
                width: 201px;
                margin: auto;
                display: flex;
            }
        }
    }
}

.buildingPermitDescParagraph {
    margin-bottom: 0px;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .topInnerPanelContainer { display: none; }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .topInnerPanelMobileContainer { display: none; }
}