@import '../../../styles/variables';

.inner_panel_container {
    max-width: 745px;
}

.map {
    border: none;
    width: 100%;
    height: 150px;
    margin-top: 9px;
    margin-bottom: 15px;
}

.label {
    font-size: 14px;
    font-weight: bold;
}

.property_descriptor {
    margin-top: 24px;
    & p {
        margin-bottom: 0px;
    }
    & .label {
        margin-bottom: 6px;
    }
}

.property_descriptor_container {
    display: grid;
    grid-gap: 0 1rem;
    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.checkbox {
    margin-left: 30px;
    margin-bottom: 20px;
}

.errorMessage {
    color: $danger_red;
    display: block;
}

.zoomInWarning {
    color: $circle-orange;
    display: block;
    min-height: 20px; // So it takes up space even if empty
}
