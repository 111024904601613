@import '../../styles/variables';

.mobileSideBarHeader {
    height: 50px;
    display: flex !important;
    margin: 20px 10px 40px 38px;

    &:focus {
        outline: none;
    }
}

.mobileSideBarHeaderMenu {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    flex: 1;
    align-items: center;
    display: flex !important;
    font-family: Dienstag;
}

.mobileSideBarHeaderClose {
    width: 50px;
    font-weight: 600;
    align-items: center;
    display: flex;

    & i {
        font-size: 1.7em;
        margin: auto;
    }

    &:hover {
        background: $menu-hover !important;
        cursor: pointer;
    }
}

.mobileSideBarTop {
    min-height: 350px;
    padding-left: 36px;

    &:focus {
        outline: none;
    }
}

.mobileSideBarBottom {
    min-height: 300px;
    margin: 0px;
    padding-left: 36px;

    &:focus {
        outline: none;
    }
}

.mobileSideBarMenuItemContainer {
    display: flex;
    align-items: center;
    height: 50px;

    &:hover {
        background: $menu-hover !important;
        cursor: pointer;
    }
}

.mobileSideBarMenuItemLink {
    font-family: Avenir;
    font-size: 16px;
    color: $primary-white;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 700;
    height: 50px;
}

.mobileSideBarMenuItemLink:hover {
    color: $primary-white;
    background: $menu-hover 0% 0% no-repeat padding-box;
    cursor: pointer;
}

.mobileSideBar {
    background-color: $primary-blue !important;
    color: white;
    font-family: Avenir;
}

.mobileSideBarMenuOtherLinks {
    color: $mobile-nav-other-links;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.normalLinks {
    text-transform: capitalize;
}