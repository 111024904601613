@import '../../../styles/variables.scss';

.pageContainer {
    padding-left: 2%;
    padding-right: 2%;
}

.arrowContainer {
    margin-bottom: 10px;
}

.paymentHistoryHeader {
    color: #302E2E;
    font-family: 'Lato' !important;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 25px !important;
}

.topPanelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.blueHeading {
    color: #00386A;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
}

.paymentAccountText {
    margin-bottom: 5px;
}

.totalDueText {
    margin-bottom: 5px;
}

.delinquentAmountText {
    margin-bottom: 5px;
    color: #bf0000 !important;
    font-weight: bold !important;
    font-family: 'Lato' !important;
}

.todaysDateContainer {
    background-color: #F8F8F8;
    height: 40px;
    display: flex;
    align-items: center;
    padding-top: 3px;
    border-radius: 5px;

    .blueHeading {
        margin-bottom: 0;
        margin-left: 20px;
        font-size: 16px;
    }
}

.containerWeb {
    margin-left: 5px;
    width: 99.5%;
}

.containerMobile {
    display: none;
}

.paymentHistoryTableHeader {
    text-align: center;
    background: #F8F8F8;
    color: #131313;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.paymentHistoryDateHeader {
    text-align: left !important;
}

.colGroupPaymentDate {
    width: 20%;
}

.colGroupPaymentType {
    width: 50%;
}

.colGroupPaymentAmount {
    width: 20%;
}

.priceHistoryTableDate {
    padding-left: 10px;
    color: #00386A;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Lato';

    padding-top: 15px;
    padding-bottom: 15px;
}

.priceHistoryTableType {
    color: #00386A;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Lato';
    text-align: center;
}

.priceHistoryTableAmount {
    color: #00386A;
    font-size: 14px;
    font-weight: 800;
    font-family: 'Lato';
    text-align: center;
}

.containerMobile {
    margin-left: 5px;
    width: 99.5%;
}

.tableRow {
    border-bottom: 1px solid #AAAAAA;
}

.colGroupLink {
    width: 20%;
}

.colGroupPrice {
    width: 5%;
}

.colGroupButtons {
    width: 10%;
}

.billLinkContainer {
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.billLinkText {
    color: #00386A;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
    text-decoration: underline;
    margin-bottom: 0;
    width: 200px;
    cursor: pointer;
}

.billNoLinkText {
    color: #00386A;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
    margin-bottom: 0;
    width: 200px;
}

.billLinkText:hover {
    color: #0071d4;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Lato';
    text-decoration: underline;
}

.accountInformationText {
    color: #707070;
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 0;
}

.dueDate {
    margin-top: 5px;
    color: #707070;
    font-size: 14px;
}

.priceText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 800;
}

.buttonSpacer {
    margin-left: 10px;
}

.paymentInfoContainer {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: 15px;
    border-bottom: 1px solid #AAAAAA;
    padding-top: 20px;
    padding-bottom: 20px;
}

.mobileBillLinkText {
    margin-top: 5px;
}

.mobileTypeText {
    margin-top: 5px;
    color: black;
    font-family: 'Lato';
    font-size: 16px;
}

.mobileDateText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 800;
}

.mobilePaymentPriceText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 800;
    margin-top: 10px;
}

.mobilePriceText {
    color: #00386A;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 800;
}


.singleButtonContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.paymentIncomplete {
    font-family: 'Lato';
    color: #BF0000;
    font-weight: 800;
}

.paymentComplete {
    font-family: 'Lato';
    color: #77974C;
    font-weight: 800;
}

.buttonContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}


.billPayAddPaymentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;

    .billPayAddPaymentActionV2 {
        font-size: 18px;
    }
}

.billPayHeaderV2 {
    color: #302E2E;
    font-family: 'Lato' !important;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 30px;
}

.warningDelinquencyBanner {
    width: 100%;
    margin-bottom: 20px;
    border: solid transparent 1px;
    border-radius: 5px;
    background-color: #cb3232;
    color: white;
    font-family: 'Lato';
    display: flex;
    padding: 16px 24px; /* top, right, bottom, left */
    align-items: center;

    .warningDelinquencyBannerIcon {
        font-size: 36px;
        margin-right: 16px;
        margin-top: 5px;
    }

    .warningDelinquencyBannerHeader {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0px;
    }

    .warningDelinquencyBannerBody {
        font-size: 15px;
        margin-bottom: 0px;
    }
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .paymentHistoryHeader {
        margin-left: 15px;
    }
    .topPanelContainer {
        flex-direction: column;
        align-items: center;
    }
    .buttonContainer {
        flex-direction: column;
        padding-top: 20px;
    }
    .buttonSpacer {
        margin-top: 15px;
    }
    .singleButtonContainer {
        display: block;
    }
    .containerMobile {
        display: flex;
        flex-direction: column;
    }
    .containerWeb {
        display: none;
    }

    .warningDelinquencyBanner {
        flex-direction: column;
    
        .warningDelinquencyBannerIcon {
            margin-bottom: 10px;
            font-size: 50px;
        }
    
        .warningDelinquencyBannerHeader {
            margin-bottom: 5px;
        }
    }
}
