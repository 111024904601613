@import '../styles/variables.scss';

.app {
    background-color: $primary-white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    justify-content: flex-start;
    color: $primary-black;
}

.pageContainer {
    display: flex;
    flex: 1;
    width: 100%;
    padding: 20px;
}

.defaultPageBackground {
    height: 100%;
    width: 100%;
    flex-grow: 2;
    background-color: $primary-light-grey;
}

.loginPageBackground {
    height: 100%;
    width: 100%;
    flex-grow: 2;
    background: transparent url('../static/img/elgin-watermark.png') no-repeat;
    background-position: right calc(100% + 100px);
}

.authenticatedRouteContainer {
    display: flex;
    flex: 1;
    width: 100%;
    flex-grow: 2;
    background: $primary-light-grey url('../static/img/elgin-watermark.png') no-repeat;
    background-position: right calc(100% + 100px);
    justify-content: center;
}

.authenticatedScrollView {
    width: 100%;
    display: block;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .authenticatedScrollView {
        width: 100%;
        padding: 0;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .authenticatedScrollView {
        padding: 0 2vw;
        width: 100%;
        max-width: 1425px;
        margin: 0 auto;
    }
}

.authenticatedNoSalesforceRouteContainer {
    display: flex;
    flex: 1;
    width: 100%;
    flex-grow: 2;
    justify-content: center;
    background: $primary-white url('../static/img/elgin-watermark-light-grey.png') no-repeat;
    background-position: right calc(100% + 100px);
    align-items: center;
}

.unauthenticatedRouteContainer {
    display: flex;
    flex: 1;
    width: 100%;
    flex-grow: 2;
    justify-content: center;
    background-color: $primary-white;
    align-items: center;
}

.routeContainer {
    width: 100%;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .routeContainer {
        width: initial;
        display: flex;
        flex: 1;
        margin-left: 0px;
        max-width: none;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .routeContainer {
        padding-left: $sidebar-width;
        margin-top: 30px;
        display: block;
    }
}

@media only screen and (min-width: 0px) and (max-width: 668px) {
    .authenticatedRouteContainer { background-size: contain; }
    .loginPageBackground { background-size: contain; }
}


@media only screen and (min-width: 669px) {
    .authenticatedRouteContainer { background-size: initial; }
    .loginPageBackground { background-size: initial; }
}
.errorMessage {
    color: red;
    font-family: avenir;
    margin-top: 5px;
    display: inline-block;
}

.darkRedErrorMessage {
    font-family: 'Lato';
    font-size: 14px;
    margin-top: 5px;
    color: $danger-red;
    display: inline-block;
    font-weight: bold;
}