@import '../../styles/variables';

.link {
    max-width: 265px;
}

.arrowImg {
    background: url('../../static/img/left-arrow.svg') no-repeat center;
    background-size: 29px;
    width: 29px;
    height: 29px;
}

.arrowText {
    font-family: 'Lato';
    color: #005DAA;
    font-size: 20px;
    font-weight: 800;
    margin-left: 20px !important;
}

.containerCenter {
    align-items: center;
}

.container {
    display: flex;
    font-family: Avenir;
    color: $view-action-blue;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
        color: $primary-blue;
    };

    div {
        margin-left: 0;
    }

    .back_text {
        margin-left: 10px;
        max-width: 250;
    }

    .arrow_container {
        display: flex;
    }
}