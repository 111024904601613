@import '../../styles/variables';

.sideBarMenu {
    display: flex;
    flex-direction: column;
    width: $sidebar-width;
    position: fixed;
    top: $header-height + 36px;
    bottom: 0;
    box-sizing: border-box;
    padding-right: 30px;
    overflow-y: scroll;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    margin-top: 5px;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .sideBarMenu { display: none; }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .sideBarMenu { display: initial; }
}

.sideBarMenuLinkTextContainer {
    align-items: center;
    display: flex;
    color: $link-dark-grey;
    font-size: 16px;
}

i {
    margin-right: 5px;
    font-size: 20px;
}

.active {
    color: $link-blue !important;
    font-weight: 700;
}

.admin {
    margin-top: 50px;
}

.item {
    padding: 11px 11px 11px 5px;
    cursor: pointer;
    font-family: Avenir;

    :not(.active):hover {
        color: black;
    }
}

.menu {
    margin-top: 15px;

    a {
        color: inherit;
    }
}

.sideBarDropdown {
    background-color: $nav-menu-dropdown !important;
    border: none !important;
    font-family: 'Avenir';
    font-size: 16px;
    margin-bottom: 15px;
    min-height: auto !important;
    padding: .65em 2.1em .65em 1em !important;

    div {
        color: $link-dark-grey !important;
    }

    i {
        line-height: normal !important;
        top: auto !important;
        color: $link-dark-grey;
    }
}

.dropdown {
    margin-bottom: 20px;
}