@import '../../../styles/variables';

.innerPanelContainer {
    padding: 30px 15px;
}

.backToHomepageArrow {
    margin-bottom: 20px;
}

.userInfoColumn {
    padding-top: 70px;
    padding-bottom: 70px;
    width: 300px;
    border-right: 1px solid #E0E0E0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Lato' !important;
    font-weight: bold !important;
    word-wrap: break-word;
    text-align: center;

    .profilePicture {
        width: 146px;
        height: 146px;
        margin-bottom: 35px;
        clip-path: circle();
    }

    .fullName {
        width: 250px;
        color: #00386A;
        font-size: 24px;
        margin-bottom: 15px;
        margin-top: 0;
        font-family: 'Lato' !important;
        font-weight: bold !important;
    }

    .smallerUserDetails {
        width: 250px;
        font-size: 15px;
        margin-bottom: 8px;
    }
}

.userEditColumn {
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 30px;
    width: 70%;
    font-family: 'Lato' !important;

    .outerEditUserPanelHeader {
        margin-bottom: 35px;
    }

    .innerEditUserPanelHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .editUserHeader {
        margin: 0;
    }

    .inputLabel {
        font-weight: 700;
        font-family: 'Lato';
        font-size: 16px;
        margin-bottom: 5px;
    }
}

.row {
    margin-bottom: 20px;
}

.orderContainer {
    max-width: 106px;
}

.pageHeader {
    display: flex;
}

.submitContainer {
    margin-left: auto;
    > button {
        margin-left: 5px;
    }
}

.activitiesLink {
    max-width: 175px;
}

.topError {
    color: $danger-red;
    width: 100%;
    margin-top: 10px;
    font-family: 'Lato';
}

.backToPreviousPage {
    margin-bottom: 20px;
}

.editPaymentHeader {
    margin: 0;
    margin-top: 50px !important;
    margin-bottom: 35px !important;
}

h3:last-child {
    margin-bottom: 20px !important;
}

.slightlyLargerMarginBottom {
    margin-bottom: 20px !important;
}

.billingNotificationSettingsHeader {
    margin-top: 50px !important;
    margin-bottom: 16px;
}

.billingNotificationSettingsNote {
    margin-bottom: 35px;
    color: #DE6839;
    font-size: 16px;
    font-family: 'Lato';
}
