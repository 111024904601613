@import '../../styles/variables';

.icon {
    margin: 5px;
}

.iconContainer {
    display: flex;
}

.blue {
    color: $link-blue !important;

    &.clickable {
        cursor: pointer;
        &:hover {
            color: $view-action-blue !important;
            
        }

        &:active {
            color: $primary-blue!important;
        }
    }
}

.green {
    color: $circle-green !important;

    &.clickable {
        cursor: pointer;
        &:hover {
            color: $green-hover !important;
            
        }

        &:active {
            color: $green-active!important;
        }
    }
}

.red {
    color: $danger-red !important;

    &.clickable {
        cursor: pointer;
        &:hover {
            color: $danger-red-hover !important;
            
        }

        &:active {
            color: $danger-red-active!important;
        }
    }
}

.black {
    color: $primary-black !important;

    &.clickable {
        cursor: pointer;
        &:hover {
            color: $primary-grey !important;
        }

        &:active {
            color: $primary-black !important;
        }
    }
}

.orange {
    color: $circle-orange !important;

    &.clickable {
        cursor: pointer;
        &:hover {
            color: $circle-orange !important;
            
        }

        &:active {
            color: $circle-orange!important;
        }
    }
}