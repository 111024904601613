@import '../../styles/variables.scss';

.heading {
    color: #302E2E;
    font: {
        family: Dienstag;
        size: 24px;
    }
}

.action_container {
    background-color: #FDFDFD;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font: {
        family: Avenir;
        spacing: 0px;
    }
    margin: 10px 0px 30px 0px;
    padding: 20px;
}

.action_container_heading {
    color: #222222;
    display: inline-block;
    margin-right: 30px;
    text-transform: capitalize;
    font: {
        family: 'Lato' !important;
        size: 23px;
        weight: 300;
    }
}

.listing_table {
    margin-top: 30px;
}

.item_text {
    display: inline-block;
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin: 20px;
}

/* start table style */

.active {
    text-align: center;
    width: 7%;
    margin-right: 5px;

    .greenCheckmark {
        color: green;
    }
    .redX {
        color: red;
    }
}

.tag {
    display: inline-block;
    border-radius: 4px;
    color: white;
    width: 150px;
    height: 26px;
    padding: 4px 0px;
    text: {
        align: center;
        transform: capitalize
    }
    font: {
        size: 14px;
        weight: bold;
    }
}

.tableContainer {
    margin-bottom: 20px;
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Lato' !important;
}

.tableHeader {
    height: 50px;
    font-size: 16px;
    padding: 0px 14px;
    font-weight: bold;
    text-align: left;
}

.item {
    font-size: 16px;
    color: inherit;
}

td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.actions {
    text-align: center;
    width: 10%;
    
    i {
        margin: 5px;
    }
}

.editIcon {
    color: $link-blue;
}

.trashIcon {
    color: red;
}

.timestamp {
    width: 15%;
}

.alertText {
    width: 35%;
    padding-right: 20px;
    word-break: break-all;
}

.alertHeader {
    font-size: 20px;
    font-weight: bold;
}

/* end table style */

/* start modal style */

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.buttonWrapper {
    margin-top: 20px;
}

.errorMessage {
    color: red;
    font-weight: bold;
}

.modalContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    // align-items: ;
    justify-content: space-evenly;

    & > div {
        width: 100%;
    }
}

.linkText {
    cursor: pointer;
    color: #4183c4;
    &:hover {
        color: #1e70bf;
    }
}

.submitContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px !important;
    gap: 20px !important;
}

.formContainer {
    margin-top: 15px;
    margin-bottom: 15px;
}

.toggleContainer {
    margin-bottom: 20px;
}

.textEditorContainer {
    margin-bottom: 20px;
}

.titleContainer {
    width: 100%;
}

.alertDeleteModalBody {
    font-size: 17px;
}

/* end modal style */
