@import '../../styles/variables';

.title {
    font-family: 'Lato';
    font-size: 24px;
    margin-bottom: 30px;
}

.gridContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)); /* make columns all the same width */
    width: 100%;
    column-gap: 10%;
    row-gap: 30px;
}

.gridItem {
    cursor: pointer;
    height: 53px;
    border-bottom: 2px solid #222222;
    text-align: center;
    color: #222222;
    font-weight: 800;
    font-size: 14px;
    font-family: 'Avenir';
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.gridItemText {
    font-family: 'Lato';
    width: 75%;
    text-align: left;
    padding-left: 20px;
    text-transform: capitalize;
    word-break: keep-all;
}

.gridItemServiceIcon {
    background: url('../../static/img/popularServices/sample-service-icon.svg') no-repeat center;
    background-size: contain;
    width: 32px;
    height: 32px;
}

.gridItemCarrotIcon {
    background: url('../../static/img/carrot.svg') no-repeat center;
    width: 32px;
    height: 32px;
}

.viewAllLinkContainer {
    text-align: right;
    margin-top: 30px;
}

.viewAllLink {
    font-family: 'Lato';
    color: #003A88;
    font-weight: 800;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.topInnerPanelContainer {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
}

.spinnerContainer {
    margin: auto;
    min-height: 297px;
}


@media only screen and (min-width: 0px) and (max-width: 1400px) {
    .gridContainer {
        grid-template-columns: repeat(2, minmax(0, 1fr)); /* make columns all the same width */
    }
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
    .gridContainer {
        grid-template-columns: repeat(1, minmax(0, 1fr)); /* make columns all the same width */
    }
}
