@import '../../styles/variables';

.myOrganizationsContainer {
    display: flex;
    border-top: 1px solid $primary-grey;
    flex-direction: column;
}

.myOrganizationsItem {
    display: flex;
    justify-content: space-between;
    margin: {
        top: 10px;
        left: 20px;
        right: 20px;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.submitBusinessButton {
    margin-top: 10px;
}

.linkText {
    cursor: pointer;
    color: #4183c4;
    &:hover {
        color: #1e70bf;
    }
}
.inputContainer {
    display: flex;
    margin-bottom: 0px !important;
}

.address {
    display: flex;
    flex: {
        direction: column;
    }
    margin: {
        top: 5px;
        left: 20px;
        right: 30px;
    } 
}

.formContainer {
    // width: 720px;
    // max-width: 100%;
    justify-self: center;
    
    > div {
        margin-bottom: 10px;
    }
}

.cityStateZip {
    display: flex;
    flex: {
        direction: row;
        wrap: wrap;
    }
}

.city {
    flex-grow: 6;
}

.state {
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.zip {
    flex-grow: 2;
}