@import '../../../styles/variables';

.searchWrapper {
    border: 1px solid #B5B5B5;
    width: 25%;
    border-radius: 10px;
    color: #B5B5B5;
    margin-bottom: 30px;
    display: relative;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
}

.searchIcon {
    font-size: 16px;
    margin-top: 5px;
}

.searchInput {
    font-size: 14px;
    max-height: 39px;
    padding: 0px !important;
    color: $primary-black !important;
    border: none;
    width: 90%;
    line-height: 20px;

    input {
        font-family: 'Avenir' !important;
        color: $primary-black !important;
    }

    &:focus {
        outline: none;
    }
}