@import '../../styles/variables.scss';

.eventsParentContainer {
    width: 100%;
}

.elginTowerContainer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.elginTowerImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.eventsSubContainer {
    width: 100%;
    font-family: "Lato";
    display: flex;
    flex-direction: column;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.headerContainer {
    margin-bottom: 50px;
}

.gridContainer {
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    row-gap: 50px;
    margin-bottom: 50px;
}

.eventContainer {
    background-color: $primary-white;
    color: $primary-black;
    width: 325px;
    height: 325px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0px 3px 6px #00000029;
}

.eventTitleContainer {
    background-color: $primary-blue;
    color: $primary-white;
    width: 100%;
    height: 25%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

.eventDetailsContainer {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.eventDateTimeParentContainer {
    width: 100%;
    height: 50%;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.eventCalendarIconContainer {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventDateTimeSubContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.eventDateText {
    margin-bottom: 10px;
}

.eventLocationParentContainer {
    width: 100%;
    height: 50%;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.eventMarkerIconContainer {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventLocationSubContainer {
    width: 80%;
    text-align: left;
}

.allEventsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-wrap: nowrap;
}

.allEventsButton {
    background-color: $primary-blue;
    color: $primary-white;
    font-size: 20px;
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 25px;
}