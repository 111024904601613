@import '../../styles/variables';

.circle {
    font-family: 'Avenir';

    span {
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        margin:auto;
        max-width: 150px;
    }
}

.coloredCircleExtraSmall {
    width: 29px;
    height: 29px;
    border-radius: 15px;
    border: 2px solid;
    display: flex;
    margin: 5px 15px 5px 5px;

    & span {
        font-size: 16px;
        max-width: 20px;
    }
}

.coloredCircleSmall {
    width: 140px;
    height: 140px;
    border-radius: 70px;
    border: 5px solid;
    display: flex;
    margin: 10px;

    & span {
        font-size: 15px;
        max-width: 105px;
    }
}

.coloredCircleLarge {
    width: 190px;
    height: 190px;
    border-radius: 95px;
    border: 5px solid;
    display: flex;
    margin: 10px 20px 10px 20px;
}

.coloredCircleContainer {
    flex-direction: row;
    display: flex;
    margin: auto;
}

.coloredCircleRow {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.green {
    color: $circle-green;
    border-color: $circle-green;
    background-size: 200% 200%;

    &.opaque {
        border: none;
        background-color: $circle-green;
        color: $primary-white;
    }

    &.clickable {
        background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, $circle-green 50%);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        color: $circle-green;
        transition: background-size .25s, color .25s, background-color .5s;
        cursor: pointer;

        &:hover {
            background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, transparent 50%);
            background-repeat: no-repeat;
            transition: background-size .25s, color .25s, background-color .25s;
            background-size: 0 0;
            background-position: 50% 50%;
            color: $primary-white;
            background-color: $circle-green;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }
}

.purple {
    color: $circle-purple;
    border-color: $circle-purple;
    background-size: 200% 200%;

    &.opaque {
        border: none;
        background-color: $circle-purple;
        color: $primary-white;
    }

    &.clickable {
        background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, $circle-purple 50%);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        color: $circle-purple;
        transition: background-size .25s, color .25s, background-color .5s;
        cursor: pointer;

        &:hover {
            background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, transparent 50%);
            background-repeat: no-repeat;
            transition: background-size .25s, color .25s, background-color .25s;
            background-size: 0 0;
            background-position: 50% 50%;
            color: $primary-white;
            background-color: $circle-purple;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }
}

.blue {
    color: $circle-blue;
    border-color: $circle-blue;
    background-size: 200% 200%;

    &.opaque {
        border: none;
        background-color: $circle-blue;
        color: $primary-white;
    }

    &.clickable {
        background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, $circle-blue 50%);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        color: $circle-blue;
        transition: background-size .25s, color .25s, background-color .5s;
        cursor: pointer;

        &:hover {
            background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, transparent 50%);
            background-repeat: no-repeat;
            transition: background-size .25s, color .25s, background-color .25s;
            background-size: 0 0;
            background-position: 50% 50%;
            color: $primary-white;
            background-color: $circle-blue;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }
}

.orange {
    color: $circle-orange;
    border-color: $circle-orange;
    background-size: 200% 200%;

    &.opaque {
        border: none;
        background-color: $circle-orange;
        color: $primary-white;
    }

    &.clickable {
        background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, $circle-orange 50%);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        color: $circle-orange;
        transition: background-size .25s, color .25s, background-color .5s;
        cursor: pointer;

        &:hover {
            background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, transparent 50%);
            background-repeat: no-repeat;
            transition: background-size .25s, color .25s, background-color .25s;
            background-size: 0 0;
            background-position: 50% 50%;
            color: $primary-white;
            background-color: $circle-orange;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }
}

.gray {
    color: $circle-gray;
    border-color: $circle-gray;
    background-size: 200% 200%;

    &.opaque {
        border: none;
        background-color: $circle-orange;
        color: $primary-white;
    }

    &.clickable {
        background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, $circle-gray 50%);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        color: $circle-gray;
        transition: background-size .25s, color .25s, background-color .5s;
        cursor: pointer;

        &:hover {
            background-image: radial-gradient(circle at center , rgba(255, 255, 255, 0.7) 50%, transparent 50%);
            background-repeat: no-repeat;
            transition: background-size .25s, color .25s, background-color .25s;
            background-size: 0 0;
            background-position: 50% 50%;
            color: $primary-white;
            background-color: $circle-gray;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }
}

.blur {
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

@media only screen and (min-width: 0px) and (max-width: 1409px) {
    .coloredCircleLarge { 
        width: 145px;
        height: 145px;
        margin: 10px 12px 10px 12px;

        span {
            font-size: 20px;
            max-width: 125px;
        }
     }
}

@media only screen and (min-width: 1410px) {
    .coloredCircleLarge { 
        width: 190px;
        height: 190px;
        margin: 10px 20px 10px 20px;

        span {
            font-size: 22px;
            max-width: 150px;
        }
     }
}