@import '../../styles/variables';

.footer {
    display: flex;
    background-color: $primary-blue;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 200px;
    padding-right: 200px;
}

.footerMobileTop {
    flex-basis: 150px;
    flex-shrink: 0;
    margin-right: 100px;
}

.footerMobileBottom {
    text-align: center;
}

.footerMiddleContainer {
    display: flex;
    flex-grow: 1;
    flex-basis: 150px;
    flex-shrink: 0;
    margin-left: 100px;
    margin-right: 100px;
}

.footerMiddle {
    max-width: 700px;
    display: flex;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
}

.footerSpace {
    flex-shrink: 1;
    flex-basis: 200px;
}

.footerLogo {
    background: url('../../static/img/footer-logo.svg') no-repeat center;
    width: 125px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
}

.footerLinkContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.footerLinkMiddleContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.footerLinkItemContainer {
    color: $primary-white;
    font-weight: 700;
    font-family: Avenir;
    font-size: 16px;
    flex: 1;
    padding: 5px;
}

.footerInfoItemContainer {
    color: $primary-white;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 100;
    flex: 1;
    padding: 5px;
}

.footerMobile {
    display: none;
    flex: 1;
    flex-direction: column;
}

@media only screen and (min-width: 0px) and (max-width: 1215px) {
    .footerMobile {
        display: flex;
    }
    .footerWeb {
        display: none;
        flex: none;
    }
    .footer {
        padding: 40px 100px;
    }
    .footerMobileTop {
        margin-left: auto;
        margin-right: auto;
    }
    .footerMobileBottom {
        flex-direction: column;
    }
}

@media only screen and (min-width: 1216px) {
    .footerMobile {
        display: none;
    }
    .footerWeb {
        display: flex;
        flex: 1;
    }
    .footer {
        padding: 40px 0px;
    }
    .footerMobileTop {
        margin-left: initial;
        margin-right: 100px;
    }
    .footerMobileBottom {
        flex-direction: row;
    }
}

.footerMobileLogo {
    background: url('../../static/img/footer-logo.svg') no-repeat center;
    width: 125px;
    height: 274px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 25px;
}

.footerMobileLinkItemContainer {
    color: $primary-white;
    font-weight: 700;
    font-family: Avenir;
    font-size: 16px;
    flex: 1;
    padding: 5px;
}

.footerLinkItem {
    color: inherit;
}

.footerMobileInfoItem {
    color: $primary-white;
    font-family: Avenir;
    font-size: 14px;
    flex: 1;
    padding: 5px;
}

.footerLinkInfoContainer {
    margin-top: 50px;
}
