.action_container {
    background-color: #fdfdfd;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font: {
        family: Avenir;
        spacing: 0px;
    }
    margin: 10px 0px 30px 0px;
    padding: 25px;
}

.buttonHeader {
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
}

.title {
    color: #222222;
    display: inline-block;
    margin-bottom: 30px;
    text-transform: capitalize;
    font: {
        family: 'Lato' !important;
        size: 23px;
        weight: 300;
    }
}

.alertText {
    width: 35%;
    padding-right: 20px;
    word-break: break-all;
}

.alertHeader {
    font-size: 20px;
    font-weight: bold;
}

td {
    padding: 10px;
}
