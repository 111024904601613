@import '../../styles/variables.scss';

.newsParentHomeContainer {
    max-width: 1500px;
}

.newsParentDashboardContainer {
    max-width: 1000px;
}

.newsParentHomeContainer, 
.newsParentDashboardContainer {
    font-family: "Avenir";
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
}

h2 {
    text-align: center;
}

.newsTitleContainer {
    text-align: center;
    margin-bottom: 70px;
}

.newsItemContainer {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.newsItemSubContainer {
    background-color: $primary-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

.newsItemImageContainer {
    max-height: 250px;
    overflow: hidden;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.newsItemImage {
    width: 100%;
    object-fit: contain;
    align-self: center;
}

.newsItemPublishDateContainer {
    display: flex;
    justify-content: center;
}

.newsItemPublishDateTextContainer {
    background-color: $primary-light-grey;
    width: 100%;
    text-align: center;
    border-radius: 25px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsItemPublishDateText {
    color: $primary-blue;
    font-size: 16px;
    padding: 10px;
}

.newsItemTitleContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.newsItemTitleText {
    color: $primary-black;
    font-size: 20px;
    font-weight: bold;
}

.newsItemContentSnippetContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.newsItemContentSnippetText {
    font-size: 16px;
}

.newsItemLinkContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 20px;
}

.newsItemLinkText {
    color: $primary-blue;
    font-size: 16px;
}

:global(.slick-arrow)::before {
    // Remove arrow icon from react-slick default styling
    content: none;
}