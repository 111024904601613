@import 'variables';

@font-face{
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/avenir-medium-webfont.woff2) format('woff2'), url(./fonts/avenir-medium-webfont.woff) format('woff');
    font-weight: 500;
}

@font-face{
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/avenir-light-webfont.woff2) format('woff2'), url(./fonts/avenir-light-webfont.woff) format('woff');
    font-weight: 300;
}


@font-face{
    font-family: 'Avenir';
    src: local('Avenir'), url(./fonts/avenir-heavy-webfont.woff2) format('woff2'), url(./fonts/avenir-heavy-webfont.woff) format('woff');
    font-weight: 700;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $primary-black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h2 {
    font-family: dienstag, sans-serif !important;
    font-style: normal;
    font-weight: 100;
}

h4 {
    font-family: Avenir !important;
    font-size: 22px;
}

h3 {
    font-family: Avenir !important;
    font-size: 23px;
    font-weight: 100;
    margin-top: 0;
}

.headerContainer {
    display: flex;
}

.listItemColor {
    background-color: #F4F4F4;
    &:nth-child(even) {
        background-color: #FAFAFA;
    }
}

.esri-popup__content .esri-feature-content {
    p {
        margin-bottom: 0;
    }
}
.centerText {
    display: block;
    text-align: center;
}

// Toast Styling
.ui-alerts {
    position: absolute;
    z-index: 100;
    bottom: 10px;

    @include desktop {
        top: calc(#{$header-height} + 10px);
        right: 15px;
    }

}

.modal {
    bottom: unset !important;
    top: unset !important;
    right: unset !important;
    left: unset !important;
}
