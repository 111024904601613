@import '../../styles/variables';

.form {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: column;
    width: 260px;
    padding-bottom: 15px;
}

.center {
    align-self: center;
}

.page {
    font-family: Avenir;
    width: 100%;
    background-color: $primary-white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(
      to bottom, transparent, transparent 70%, $primary-white
    ), url("../../static/img/Elgin-City-View.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

html.webp .topPage {
    background-image: linear-gradient(
      to bottom, transparent, transparent 70%, $primary-white
    ), url("../../static/img/Elgin-City-View.webp");
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .headerLogo {
        background: url('../../static/img/city-in-suburbs.svg') no-repeat center;
        width: 320px;
        height: 190px;
        margin: {
            top: 35px;
        }
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.activityContainer {
    background-color: #e6e6e6f5;
    max-width: 1045px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .activityContainer {
        width: 95%;
        padding: 22px;
        margin: {
            left: 10px;
            right: 10px;
        }
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .activityContainer {
        width: 100%;
        padding: 15px;
    }
}

.activitySelection {
    display: flex;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .activitySelection {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .activitySelection {
        flex-direction: row;
        justify-content: space-evenly;
    }
}

.activityColumn {
    font-size: 22px;
    width: 210px;
}

.columnHeader {
    display: flex;
    align-items: center;
}

.itemDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.errorMessage {
    color: $danger-red;
    text-align: center;
}

.descriptionHeader {
    font-size: 22px;
    text-transform: capitalize;
}

.descriptionText {
    font-size: 16px;
    margin: {
        top: 15px;
        bottom: 15px;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .descriptionText {
        margin: {
            right: 170px;
            left: 170px;
        }
    }
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .arrowContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
    }

    .arrowBar {
        border-right: 3px solid $circle-purple;
        height: 25px;
    }

    .arrowHead {
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        
        border-top: 10px solid $circle-purple;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .arrowContainer {
        display: flex;
        align-items: center;
        margin-top: 35px;
    }

    .arrowBar {
        border-top: 3px solid $circle-purple;
        width: 70px;
    }

    .arrowHead {
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        
        border-left: 10px solid $circle-purple;
    }
}

.circleContainer {
    width: 100%;
    margin-top: 70px;
    margin-bottom: 230px;

    div {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.bottomPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
