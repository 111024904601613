.buttonWrapper {
    margin-top: 20px;
}

.errorMessage {
    color: red;
    font-weight: bold;
}

.modalContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    // align-items: ;
    justify-content: space-evenly;
    text-align: center;

    & > div {
        width: 100%;
    }
}

.linkText {
    cursor: pointer;
    color: #4183c4;
    &:hover {
        color: #1e70bf;
    }
}

.buttonContainer {
    display: flex;
}

.buttonInnerContainerDualButtons {
    margin-top: 50px !important;
}

.buttonInnerContainerSingleButton {
    margin-top: 30px !important;
}

.buttonInnerContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.formContainer {
    margin-top: 15px;
    margin-bottom: 15px;
}

.successIcon {
    color: green;
    margin-right: 10px;
}

.errorIcon {
    color: red;
    margin-right: 10px;
}

.messageHeader {
    margin-bottom: 20px;
    text-align: center;
}

.messageHeaderInnerContainer {
    font-size: 26px;
    text-align: center;
    font-weight: 800;
}

.messageHeaderInnerContainerWarning {
    font-size: 20px;
    text-align: center;
    font-weight: 800;
}

.messageBody {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

.messageBodyWarning {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
}

.messageHeaderImg {
    height: 64px;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    flex: 1;
    margin-bottom: 20px;
}

.messageHeaderImgError {
    background: url('../../static/img/red-cross.png') no-repeat center;
}

.messageHeaderImgSuccess {
    background: url('../../static/img/green-checkmark.png') no-repeat center;
}

.messageHeaderWarning {
    margin-bottom: 8px;
}

.messageHeaderImgWarning {
    margin-top: 10px;
    background: url('../../static/img/red-warning-triangle.png') no-repeat center;
}

:global(.dimmer) {
    background-color: rgba(0,0,0,.50) !important;
}

.modalBackgroundStyle {
    -webkit-box-shadow: 0px 0px 28px 4px rgba(0,0,0,0.7) !important;
    -moz-box-shadow: 0px 0px 28px 4px rgba(0,0,0,0.7) !important;
    box-shadow: 0px 0px 28px 4px rgba(0,0,0,0.7) !important;
    border-radius: 10px !important;
}
