@import '../../styles/variables';

.textarea {
    font-size: 16px;
    min-height: 115px;
    display: flex;
    padding: .67857143em 1em;
    color: $primary-black !important;
    resize: none;
    width: 100%;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    outline: none;
    font-family: 'Avenir' !important;
    color: $primary-black !important;

    &:focus-within {
        border-color: #96c8da !important;
        border-width: 1px !important;
        border-style: solid !important;
    }
}