@import '../../styles/variables';

.nonAuthUserContainer {
    margin: auto;
    width: 60%;
    padding-left: 10%;
}

.backToHomepageArrow {
    margin-left: 10% !important;
}

.actionContainer {
    margin-top: 20px;
    margin-bottom: 50px;
}

.linkText {
    font-family: 'Lato';
    text-decoration: underline;
    color: #00386A;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    text-transform: capitalize;
}

.actionContainerHeader {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.title {
    font-family: 'Lato';
    font-size: 24px;
    margin-top: 0px;
    margin-left: 25px;
}

.applyActionImg {
    background: url('../../static/img/popularServices/apply-icon.svg') no-repeat center;
}

.findActionImg {
    background: url('../../static/img/popularServices/find-icon.svg') no-repeat center;
}

.registerActionImg {
    background: url('../../static/img/popularServices/register-icon.svg') no-repeat center;
}

.payActionImg {
    background: url('../../static/img/popularServices/pay-icon.svg') no-repeat center;
}

.reportActionImg {
    background: url('../../static/img/popularServices/report-icon.svg') no-repeat center;
}

.defaultActionImg {
    background: url('../../static/img/popularServices/sample-service-icon.svg') no-repeat center;
}

.actionImg {
    background-size: contain;
    width: 32px;
    height: 32px;
}

.actionOuterContainer {
    margin-top: 50px !important;
    display: flex;
}

.actionInnerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.topInnerPanelContainer {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 100%;
}


@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .nonAuthUserContainer {
        width: auto;
        padding: 0 50px;
    }

    .authUserContainer {
        padding: 0 50px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 750px) {

    .nonAuthUserContainer {
        margin: auto;
        width: auto;
        padding: 0 50px;
    }

    .actionOuterContainer {
        flex-direction: column;
    }
    
    .backToHomepageArrow {
        margin-left: 0px !important;
    }
}
