@import '../../styles/variables';

.toggle {
    font-size: 16px;
    min-height: 115px;
    display: flex;
    padding: 0px !important;
    color: $primary-black !important;
    resize: none;
    margin-right: 40px;
    
    input {
        font-family: 'Avenir' !important;
        color: $primary-black !important;
    }
}

.toggleContainer {
    display: flex;

    div {
        font-weight: 500;
        font-size: 14px;
    }
}