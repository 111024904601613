@import '../../styles/variables';

.billPayDetails {
    color: $bill-details-grey;
    font-size: 18px;
    font-style: italic;
    border: 1px solid transparent;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
        border: 1px solid $light-blue-hover;
    }
}
.billPayDetailItem {
    padding: 15px 25px;
}
ul.leaders {
    max-width: 60em;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
}
.billPayDetailItemType:after {
    float: left;
    width: 0;
    white-space: nowrap;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
}
ul.leaders span:first-child {
    padding-right: 0.33em;
    background: white;
}
ul.leaders span + span {
    float: right;
    padding-left: 0.33em;
    background: white;
}