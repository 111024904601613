@import '../../../styles/variables';

.innerPanelContainer {
    max-width: 745px;
}

.inputLabel {
    font-weight: 700;
    font-family: 'Lato';
    font-size: 13px;
    margin-bottom: 5px;
}

.row {
    margin-bottom: 20px;
}

.orderContainer {
    max-width: 106px;
}

.pageHeader {
    display: flex;
}

.submitContainer {
    margin-left: auto;
    > button {
        margin-left: 5px;
    }
}

.activitiesLink {
    max-width: 175px;
}

.topError {
    color: $danger-red;
    width: 100%;
    text-align: center;
}