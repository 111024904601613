.memberTableHeader {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
}

.memberTableItem {
    height: 50px;
    font-size: 16px;
}

.noMembers {
    margin-top: 40px;
    text-align: center;
}

.memberRow {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    &:hover {
        .leftColumn {
            font-weight: bold;
        }
    }
    .leftColumn {
        display: inline-flex;
        align-items: center;
    }
    .rightColumn {
        display: inline-flex;
        align-items: center;
    }
    span {
        &:hover {
            font-weight: bold;
        }
    }
    .approveIcon {
        color: green;
        font-size: 36px;
        margin-top: 3px; // font-awesome uses the ::before selector which adds 5px of height and offsets the icon...?
        cursor: pointer;
    }
    .denyIcon {
        color: red;
        font-size: 36px;
        margin-left: 25px;
        margin-top: 3px; // font-awesome uses the ::before selector which adds 5px of height and offsets the icon...?
        cursor: pointer;
    }
}