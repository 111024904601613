@import '../../styles/variables.scss';

.subformWrapper {
  margin-top: 40px;
}

.table {
  border-collapse: collapse;
  border: none;
  width: 100%;

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even){background-color: #f2f2f2;}

  tr:hover {background-color: #ddd; cursor: pointer;}

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $primary-blue;
    color: white;
    
    &:hover {
      cursor: default;
    }
  }
}

.detailsWrapper {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;
  }

  .detailRow {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
