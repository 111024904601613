@import '../../../styles/variables.scss';

@media only screen and (min-width: $breakpoint-desktop-min) {
    .unauthenticatedView {
        padding: 0 2vw;
        width: 100%;
        max-width: 1200px;
        margin: 20px auto;
        display: unset;
    }
}

.headerContainer {
    flex-direction: column;
}

.layoutItem {
    margin-bottom: 30px;
}
