@import '../../styles/variables.scss';

.tableContainer {
    margin-bottom: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.addContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.item {
    height: 50px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.tag {
    display: inline-block;
    border-radius: 4px;
    color: white;
    background-color: $bill-details-grey;
    width: fit-content;
    height: 26px;
    padding: 4px 0px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    font-family: 'Lato';
    text: {
        align: center;
        transform: capitalize
    }
    font: {
        size: 15px;
        weight: bold;
    }
}

.deleteAccount {
    background-color: $danger-red;
}

.clickable {
    &:hover {
        cursor: pointer;
    }
}

.noItems {
    text-align: center;
}

.titleContainer {
    display: flex;
    justify-content: flex-start;
}
