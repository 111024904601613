.page_header {
    display: flex;
}

.permitDescription {
    max-width: 745px;
}

.dropdownContainer {
    max-width: 330px;
    margin-bottom: 40px;
}

.checkbox {
    margin-left: 30px;
    margin-bottom: 20px;
}

.nextButton {
    margin-top: 40px;
    margin-right: auto;
}