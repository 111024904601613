@import '../../styles/variables.scss';

$payment-max-width: 300px;

.paymentConfirmationContainer {
    margin-top: 30px;
}

.successIcon {
    color: green;
    margin-right: 10px;
}

.paymentProfileContainer {
    width: 100%;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border: 1px solid $primary-grey;
    border-radius: 12px;
    max-width: $payment-max-width;
    justify-content: center;
    padding: 20px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background-color: $primary-light-grey;
    }

    & * {
        align-self: center;
    }

    &.selected {
        border-color: $primary-blue;
        background-color: #dae7f9;
        font-weight: bold;
    }
}

.checkboxRow {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.submitButtonContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: $payment-max-width;
    margin-top: 10px !important;
}

.orSpacer {
    padding-top: 40px;
    padding-bottom: 40px;
}

.headerSpacer {
    padding-top: 10px;
    padding-bottom: 10px;
}

.itemizedBillTotal {
    color: black;
    font-weight: bold;
}

.itemizedTable {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-align: left;
    padding-right: 20%;
    font-size: 17px;
    border-spacing: 15px;
    border-collapse: separate;
}

.paymentConfirmationContainer {
    margin: auto;
}

.slightMarginRight {
    margin-right: 5px;
}
