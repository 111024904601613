@import '../../styles/variables';

.billPayDetails {
    color: $bill-details-grey;
    font-size: 14px;
    font-style: italic;
}
ul.leaders {
    max-width: 40em;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
}
ul.leaders li:before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
}
ul.leaders span:first-child {
    padding-right: 0.33em;
    background: white;
}
ul.leaders span + span {
    float: right;
    padding-left: 0.33em;
    background: white;
}

.billPayNoneFound {
    color: #302E2E;
    font-family: 'Lato' !important;
    font-size: 32px;
    font-weight: 500;
}

.billPayDetailsOweV2 {
    color: #302E2E;
    font-family: 'Lato' !important;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
}

.billPayDetailsUpcomingV2 {
    color: #302E2E;
    font-family: 'Lato' !important;
    font-size: 18px; 
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
}

.billPayDetailsV2 {
    color: $bill-details-grey;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 20px;
}