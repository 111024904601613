.page_header {
    display: flex;
}

.inner_panel_container {
    max-width: 825px;
    display: grid;
    grid-gap: 1rem;
    @media (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.textarea_container {
    font-size: 13px;
    & > textarea {
        min-height: 50px;
        height: 91px;
    }
}

.listing_table {
    margin-top: 30px;
}

.list_item {
    font-size: 16px;
    padding: 14px 24px;
    & > p {
        word-wrap: break-word;
    }
}

.submit_container {
    margin-left: auto;
}