.heading {
    color: #302E2E;
    font: {
        family: Dienstag;
        size: 24px;
    }
}

.deleteLink {
    cursor: pointer;
    color: red;
}