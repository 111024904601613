@import '../../styles/variables';

h2 {
    margin: 0;
    font-family: Avenir;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .mobileDashboard { display: flex; }
    .webDashboard { display: none; }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .mobileDashboard { display: none; }
    .webDashboard { display: flex; }
}

.mobileDashboard, .webDashboard {
    flex-direction: column;
    flex: 1;
}

.mobileHeader {
    margin-left: 25px;
}

.inlineAttentionContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $circle-orange;
    margin-left: 15px;
    cursor: pointer;

    &:hover {
        color: $orange-hover;
    };

    .inlineAttentionText {
        font-size: 22px;
        font-family: Avenir;
        flex: 1;
        margin: auto;
        margin-right: 15px;
        font-weight: 700;
    }
}

.attentionInnerPanelContainer {
    margin-left: 45px;
    margin-right: 45px;
    flex-direction: column;
    flex: 1;
    color: $circle-orange;
    display: flex;

    i {
        font-size: 32px;
        margin-right: 10px;
    }

    .attentionTopContainer {
        display: flex;
        justify-content: center;
    }

    h3 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .itemsAttentionNumber {
        font-size: 116px;
        font-family: Avenir;
        flex: 1;
        margin: auto;
        margin-right: 15px;
        font-weight: 700;
    }

    .itemsAttentionText {
        color: $primary-black;
        flex: 2;
        margin: auto;
        font-size: 20px;
        font-family: Avenir;
    }

    .attentionBottomContainer {
        text-align: center;
        font-family: Avenir;
        color: $view-action-blue;
        cursor: pointer;
        &:hover {
            color: $primary-blue;
        };
        margin-top: auto;
    }

    .attentionMiddleContainer {
        min-height: 158px;
        display: flex;
        margin: auto;
    }
}

.activityInnerPanelContainer {
    flex-direction: column;
    flex: 1;
    color: $primary-light-blue;
    display: flex;

    i {
        font-size: 32px;
        margin-right: 10px;
    }

    .activityTopContainer {
        display: flex;
        justify-content: center;
    }

    h3 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .activityBottomContainer {
        text-align: center;
        font-family: Avenir;
        color: $view-action-blue;
        margin-top: auto;
        cursor: pointer;

        &:hover {
            color: $primary-blue;
        };
    }

    .activityMiddleContainer {
        min-height: 158px;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .recentActivityLoadingContainer {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    table {
        border-collapse: collapse;
        display: flex;
        flex: 1;

        tbody {
            font-family: Avenir;
            flex: 1;

            tr {
                height: 50px;
                display: flex;
                padding: 8px;

                td {
                    .leftTd {
                        color: $primary-black;
                    }
                }
            }
            tr:nth-child(odd) {
                background-color: $table-light-grey;
            }
            tr:nth-child(even) {
                background-color: $table-white;
            }
        }
    }
}

.activityStatus {
    &.orange { color: $circle-orange;}
    &.blue { color: $circle-blue;}
    &.red { color: $danger-red;}
    &.green { color: $circle-green;}
    &.gray { color: $link-dark-grey;}
    &.yellow { color: $circle-yellow;}
}

.noActivities {
    color: $primary-black;
    width: 100%;
    text-align: center;
}

.status {
    display: flex;
    flex-direction: column;
    flex: 4;
    justify-content: center;
    text-align: center;
}

.activityDate {
    font-size: 12px;
    color: $activity-date-grey;
}

.activityName {
    color: $primary-black;
    margin: auto;
    flex: 4;
}

.arrow {
    flex: 1;
    margin: auto;
    justify-content: center;
    display: flex;

    div {
        margin: 0;
    }
}

.billPayInnerPanelContainer {
    display: flex;

    .billPayLeftContainer {
        flex: 1;
        width: 100%;
        min-height: 100px;
        @include desktop {
            flex: 2;
            width: 60%;
            display: flex;
            min-height: auto;
            max-height: 500px;
        }
    }
    .billPayRightContainer {
        flex-direction: column;
        flex: 1;
        color: $circle-green;
        display: flex;
        width: 40%;

        i {
            font-size: 32px;
            margin-right: 10px;
        }

        .billPayRightTopContainer {
            display: flex;
            justify-content: center;
        }

        .billPayRightBottomContainer {
            max-width: 100%;
            min-height: 250px;
            display: flex;
            flex-direction: column;
            font-family: Avenir;

            .billPayRightUpcoming {
                font-weight: 500;
                color: $primary-black;
                font-size: 18px;
            }
        }

        .billPayRightMiddleContainer {
            margin-top: 25px;
            margin-bottom: 25px;

            .billPayRightHrContainer {
                display: flex;

                hr {
                    flex: 1;
                }
            }

            .billpayButtonContainer {
                width: 156px;
                margin: auto;
                display: flex;
            }
        }

        h3 {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

.billPayAddPaymentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;

    .billPayAddPaymentTitle {
        flex: 1;
        color: $circle-green;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 30px;

        i {
            font-size: 32px;
            margin-right: 10px;
        }

        h3 {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .billPayAddPaymentAction {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .billPayAddPaymentActionV2 {
        font-size: 18px;
    }
}

.billPayHr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #B5B5B5;
    margin: 1em 0;
    padding: 0;
    margin-right: 0;
    margin-left: 0;
}

.billPayHeaderV2 {
    color: #302E2E;
    font-family: 'Lato' !important;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 30px;
}

.upcomingBillHeaders {
    margin: 0;
    margin-bottom: 30px;
    color: #302E2E;
    font-size: 18px;
    font-weight: 400;
}

.upcomingBillHeadersSpacing {
    margin-top: 10px;
}

.payBillButtonV2 {
    text-align: center;
    margin-bottom: 20px;
}

.activityInnerPanelMobileContainer {
    margin-top: 30px;
    margin-bottom: 30px;
    flex-direction: column;
    flex: 1;
    color: $primary-light-blue;
    display: flex;

    i {
        font-size: 32px;
        margin-right: 10px;
    }

    .activityTopContainer {
        display: flex;
        justify-content: center;
    }

    h3 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .activityBottomContainer {
        text-align: center;
        font-family: Avenir;
        color: $view-action-blue;
        margin-top: auto;
        cursor: pointer;

        &:hover {
            color: $primary-blue;
        };
    }

    .activityMiddleContainer {
        min-height: 158px;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    table {
        border-collapse: collapse;
        display: flex;
        flex: 1;

        tbody {
            font-family: Avenir;
            flex: 1;

            tr {
                height: 100px;
                display: flex;
                padding: 8px;
                flex-direction: column;

                td {
                    .leftTd {
                        color: $primary-black;
                    }
                }
            }
            tr:nth-child(odd) {
                background-color: $table-light-grey;
            }
            tr:nth-child(even) {
                background-color: $table-white;
            }
        }
    }
}

.billPayInnerPanelMobileContainer {
    display: flex;

    .billPayContainer {
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
        flex: 1;
        color: $circle-green;
        display: flex;

        i {
            font-size: 32px;
            margin-right: 10px;
        }

        .billPayTopContainer {
            display: flex;
            justify-content: center;
        }

        .billPayBottomContainer {
            min-height: 100px;
            display: flex;
            flex-direction: column;
            font-family: Avenir;

            .billPayUpcoming {
                font-weight: 500;
                color: $primary-black;
                font-size: 18px;
            }
        }

        .billPayMiddleContainer {
            margin-bottom: 25px;
            margin-top: 25px;

            .billPayHrContainer {
                display: flex;

                hr {
                    flex: 1;
                }
            }

            .billpayButtonContainer {
                width: 156px;
                margin: auto;
                display: flex;
            }
        }

        h3 {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

.dropdown {
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 239px;
}

tspan {
    font-size: 14px;
    font-family: 'Avenir';
}

line:last-child {
    stroke-opacity: 0;
}

.graphNoData {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.billPayGraphMobileContainer {
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 100px;
    width: 100%;
}

.bottomPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.billPayInnerPanelMobileContainer {
    & > .billPayContainer {
        margin: 0 0;
    }
}
