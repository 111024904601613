@import '../../styles/variables';

.arrow {
    width: 77px;
    display: flex;
    margin-left: 10px;
    flex: 1 !important;

    .point {
        margin-top: auto;
        margin-bottom: auto;
        width: 0;
        height: 0;
        float: right;
    }

    &.small {
        max-width: 35px;

        .line {
            width: 25px;
            background: $primary-grey;
            height: 1px;
            float: left;
            margin: auto;
        }
          
        .point {
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 11px solid $primary-grey;
        }

        &.purple {
            .point {
                border-left: 11px solid $circle-purple;
            }
            .line {
                background: $circle-purple;
            }
        }
        
        &.blue {
            .point {
                border-left: 11px solid $primary-light-blue;
            }
            .line {
                background: $primary-light-blue;
            }
        }

        &.up {
            height: 35px;
        }
        
        &.down {
            height: 35px;
        }
    }

    &.normal {
        .line {
            background: $primary-grey;
            height: 2px;
            float: left;
            margin: auto;
            flex: 1;
        }
          
        .point {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 13px solid $primary-grey;
        }

        &.purple {
            .point {
                border-left: 13px solid $circle-purple;
            }
            .line {
                background: $circle-purple;
            }
        }
        
        &.blue {
            .point {
                border-left: 13px solid $primary-light-blue;
            }
            .line {
                background: $primary-light-blue;
            }
        }

        &.up {
            height: 77px;
        }
        
        &.down {
            height: 77px;
        }
    }
}
  
.left {
    transform: scale(-1, 1);
}

.right {
    transform: scale(1, 1);
}

.up {
    transform: rotate(270deg);
}

.down {
    transform: rotate(90deg);
}