.heading {
    color: #302E2E;
    font: {
        family: Dienstag;
        size: 24px;
    }
}

.action_container {
    background-color: #FDFDFD;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font: {
        family: Avenir;
        spacing: 0px;
    }
    margin: 10px 0px 30px 0px;
    padding: 25px;
}

.action_container_heading {
    color: #222222;
    display: inline-block;
    margin-right: 30px;
    text-transform: capitalize;
    font: {
        family: Avenir;
        size: 23px;
        weight: 300;
    }
}

.listing_table {
    margin-top: 30px;
}

.list_item {
    min-height: 50px;
    font-size: 16px;
    cursor: pointer;
}

.item_text {
    display: inline-block;
    font-size: 16px;
    margin: 14px 24px;
}

.tag {
    display: inline-block;
    border-radius: 4px;
    color: white;
    width: 150px;
    height: 26px;
    padding: 4px 0px;
    text: {
        align: center;
        transform: capitalize
    }
    font: {
        size: 14px;
        weight: bold;
    }
}

.LASERFICHE_FORM {
    background-color: #4BAEFF;
}

.CUSTOM_FORM {
    background-color: #895375;
}

.EXTERNAL_LINK {
    background-color: #DE6839;
}

.CONTENT {
    background-color: #77974C;
}
