@import '../../styles/variables';

.row {
    display: flex;
    align-items: center;
}

.menu {
    position: sticky;
    top: 0;
    background: $primary-blue 0% 0% no-repeat padding-box;
    width: 100%;
    display: flex;
    z-index: 100;
    height: $header-height;

    // bottom border
    padding-bottom: 7px;
    border-bottom: 7px solid;
    border-color: rgb(197, 207, 226);
}

.menuItem {
    font-family: Avenir;
    font-size: 16px;
    color: $primary-white;
    display: flex;
    align-items: center;
    padding: 20px;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 700;
    height: 90px;
    white-space: pre;

    &.dropdown {
        > :global(.dropdown) {
            display: flex;
            align-items: center;
            border: none !important;
            > :global(div.text) {
                color: $primary-white !important;
            }
        }
        div {
            div {
                span {
                    color: $primary-black !important;
                }
            }

            i {
                color: #fff !important;
            }

            .item {
                color: black;
            }
        }
    }
}

.menuItem:hover {
    color: $primary-white;
    background: $menu-hover 0% 0% no-repeat padding-box;
    cursor: pointer;
}

.menuLogo {
    background: url('../../static/img/elgin-logo-white.png') no-repeat center;
    width: 171px;
    height: 81px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 20px;
    margin-right: 20px;
}

.menuLeftContainer {
    display: flex;
    align-items: center;
    background-color: initial !important;
    margin: 0 !important;
    border: none !important;
    box-shadow: none !important;
}

.menuRightContainer {
    margin-left: auto;
    display: flex;
}

.menuMobileRightContainer {
    visibility: hidden;
    margin-left: auto;
    display: flex;
    flex: 1;
    max-width: 80px;

    &:hover {
        background: $menu-hover !important;
        cursor: pointer;
    }

    &:active {
        background: $menu-hover !important;
    }
}

.menuMobileLeftContainer {
    display: flex;
    align-items: center;
    background-color: initial !important;
    margin: 0 !important;
    border: none !important;
    box-shadow: none !important;
    width: 80px;

    &:hover {
        cursor: pointer;
        background: $menu-hover !important;
    }

    &:active {
        background: $menu-hover !important;
    }
}

.headerSearchContainer {
    display: flex;
    width: 100%;
    margin: auto 8px auto 8px;

    // float to end
    margin-left: auto;
}

.headerMobileSearchContainer {
    margin: auto 15px auto 0px;
}

.headerMobile {
    display: none;
    background: none;
}

.headerHamburger {
    flex: 1;
    height: initial !important;
    border-radius: 0px !important;
    color: $primary-white !important;
    font-size: 2em !important;
    margin: 0 !important;
}

.headerWeb {
    flex-direction: column;
}

@media only screen and (min-width: 0px) and (max-width: $breakpoint-mobile-max) {
    .headerMobile {
        display: flex;
        flex-direction: column;
    }
    .headerWeb {
        display: none;
    }
    .menu {
        padding-left: 0px;
        padding-bottom: 0px;
    }
    .menuLogo {
        flex: 1;
        margin-right: 0px;
    }
    .headerSearchContainer {
        flex: 1;
        display: flex;
    }
    .menuMobileRightContainer {
        visibility: visible;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .headerMobile {
        display: none;
    }
    .headerWeb {
        display: flex;
    }
    .menuLogo {
        flex: initial;
        margin-right: 20px;
    }
    .headerSearchContainer {
        margin: auto 15px auto auto;
        flex: initial;
        display: initial;
    }
    .menuMobileRightContainer {
        visibility: hidden;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) {
    .menuHiddenWebSearchContainer {
        display: none;
    }
}

@media only screen and (min-width: $breakpoint-desktop-min) and (max-width: 1369px) {
    .menuHiddenWebSearchContainer {
        display: flex;

        & > .headerSearchContainer {
            display: flex;
            flex: 1;
            margin: auto 8px auto 8px;
        }
    }

    & .menuRightContainer {
        & > .headerSearchContainer {
            display: none !important;
        }
    }
}

.headerBottomContainer {
    display: none;
    @media only screen and (min-width: $breakpoint-desktop-min) and (max-width: 1369px) {
        display: flex;
        padding: 10px;
        background: $primary-blue;
        padding: 10px;
        justify-content: center;
    }
}

.menuMobileLogoContainer {
    align-items: center;
    display: flex;
    flex: 2;
}

.menuMobileLogo {
    background: url('../../static/img/elgin-horizontal-logo-white.png')
        no-repeat center;
    height: 64px;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 1;
}

.mobileSearchButtonToggle {
    height: auto !important;
    font-size: 2em !important;
    color: white;
    flex: 1;
}

.headerMobileTopContainer {
    display: flex;
    height: $header-height;
    background-color: $primary-blue;
}

.headerMobileBottomContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    background: $primary-blue;
    padding: 10px;
    justify-content: center;
}

.mobileSearchVisible {
    background: $menu-hover !important;
}

.mobileSearchInput {
    flex: 1;
    display: flex;
    font-family: 'Avenir' !important;

    & div {
        flex: 1;
        display: flex;
    }
}

.searchInput {
    input {
        background-color: transparent !important;
        border-width: 0px 0px 2px !important;
        border-style: solid !important;
        border-bottom-color: white !important;
        border-radius: 0px !important;
        padding-left: 2.67142857em !important;

        color: white !important;
        font-family: 'Avenir' !important;
        font-size: 16px;

        + i {
            color: white;
            transform: scaleX(-1);
            opacity: 1 !important;
            right: auto !important;
            font-size: 1.45em !important;
            margin-left: -10px !important;
        }

        &::selection {
            color: rgba(255, 255, 255, 0.4);
        }
    }

    .ui.icon.input {
        display: flex;

        > input {
            padding-right: 2em !important;
        }
    }
}

.menuHiddenWebSearchContainer {
    width: 80px;
}

.headerTopContainer {
    flex: 1;
    padding-left: 80px;
    flex-direction: row;
    display: flex;
    height: $header-height;
}

.userDropdown {
    width: 212px;
    font-family: Avenir;
}

.userDropdownHeader {
    // Semantic uppercases dropdown header text by default
    text-transform: none !important;
    font-family: Avenir;
    font-weight: 500 !important;
}

.userDropdownHeaderTop {
    display: flex;
    flex-direction: row;
}

.userDropdownHeaderInfo {
    min-width: 0; // needed for text-overflow ellipsis to work
    margin-right: -16px; // counteract semantic padding so email extend to end of container
    max-width: 100%;
    overflow: none;
    margin-left: 14px;
    color: $link-dark-grey;
}
.userDropdownHeaderName {
    white-space: nowrap;
    line-height: 22px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userDropdownHeaderEmail {
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userDropdownHeaderManageAccount {
    margin-top: 9px;
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    display: block;
    text-align: center;
    font-style: Avenir;
}

.userDropdownItem {
    font-size: 14px !important;
    line-height: 19px !important;
}

.userDropdownDivider {
    width: 94%;
    margin: 0 auto !important;
}

:global(#userNestedDropdownContainer) {
    // Remove the padding so that the inner nested dropdown can take up 100% of the space
    padding: 0 !important;
}

.userDropdownNestedInner {
    // Increase the dimensions to be the same size as the other dropdown items
    width: 100%;
    padding: 11px 16px 11px 16px;
    :global(.text) {
        width: 100%;
        height: 19px;
    }
}

@media only screen and (max-width: 1473px) {
    .menuLogo {
        width: 125px;
        height: 71px;
        margin-left: 10px;
        margin-right: 10px;
    }
}
